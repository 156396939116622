import gql from 'graphql-tag';

export const CARD_TYPE_FRAGMENT = gql`
  fragment cardTypeInfo on CardType {
    id
    name
  }
`;

export const CARD_FRAGMENT = gql`
  fragment CardInfo on Card {
    id
    isBlocked
    isActive
    isPhysical
    enableNFC
    enableOnline
    enableForeign
    enableWithDrawal
    type
    treezorCardId
    paymentLimit
    withDrawalLimit
    withContract
    maskedPan
    lastPayment
    lockedPin
    is3DSActive
    cardLockState
    maskedPan
    limits {
      totalAtmWeek
      totalPaymentMonth
    }
    account {
      id
      person {
        id
        name
        firstName
      }
    }
    cardType {
      ...cardTypeInfo
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;

export const CARDS_FRAGMENT = gql`
  fragment CardsInfo on CardList {
    id
    isBlocked
    isActive
    isPhysical
    type
    cardLockState
    maskedPan
    createdAt
    treezorCardId
    notViewedBo
    account {
      id
      person {
        id
        name
        firstName
      }
    }
    cardType {
      ...cardTypeInfo
    }
    wallet {
      id
      sapheerBank {
        id
        company {
          id
          legalName
        }
      }
    }
  }
  ${CARD_TYPE_FRAGMENT}
`;
