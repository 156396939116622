import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React, {
  FC,
  Fragment,
  useState,
  createContext,
  useContext,
} from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import GlobalSnackBar from './components/Common/GlobalSnackBar/GlobalSnackBar';
import Routes from './route';
import customTheme from './theme';
import { VIEW } from './utils/constant';
import {
  setThemeTypeStorage,
  getThemeTypeStorage,
} from './services/LocalStorage';
import { PaletteType } from '@material-ui/core';
import { ME } from './graphql/card/query';
import { Me } from './graphql/card/types/Me';
import { useQuery } from '@apollo/react-hooks';

let mode: PaletteType = VIEW.LIGHT;

// toggle theme from app bar
const ThemeToggleContext = createContext({
  toggle: () => {},
});

const App: FC = () => {
  const themeStorage = getThemeTypeStorage();
  const [themeType, setThemeType] = useState(themeStorage);
  setThemeTypeStorage(themeType);
  const toggleTheme = () => {
    themeType === VIEW.LIGHT
      ? setThemeType(VIEW.DARK)
      : setThemeType(VIEW.LIGHT);
    setThemeTypeStorage(themeType);
  };
  const { mode: modeGlobal, theme } = customTheme(themeType);
  mode = modeGlobal;
  useQuery<Me>(ME);
  return (
    <ThemeToggleContext.Provider value={{ toggle: toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalSnackBar />
        <BrowserRouter>
          <Fragment>
            <Routes />
          </Fragment>
        </BrowserRouter>
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export { mode };
export const useTheme = () => useContext(ThemeToggleContext);
export default App;
