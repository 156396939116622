export default {
  'services.grid.noData': `No service available`,
  'service.logo.empty': `Please insert a logo`,
  'service.logo.dark.empty': `Please insert a dark logo`,
  'service.name.empty': `Please insert a name`,
  'service.url.empty': `Please insert an uri`,
  'category.ids.not.enough': 'Please insert at least one category',
  'service.contents.empty': `Please insert a content`,
  'service.description.empty': `Please insert a description`,
  'service.color1.empty': `Please insert a color`,
  'service.color2.empty': `Please insert a second color`,
  CATEGORY_IDS_CANNOT_BE_EMPTY: `Category ids cannot be empty`,
  SERVICEPLUS_ID_CANNOT_BE_NULL: `Additional service ID cannot be empty`,
  SERVICE_PLUS_NOT_FOUND: `Additional service not found`,
  ID_OR_CONSANT_CODE_REQUIRED: `id required`,
  'save.category.error': `Error when creating category`,
  'save.service.error': `Error when creating service`,


  'delete.link.success': `Delete with success`,
  'delete.link.error': `Error when deleting`,
  'link.title.empty': `Please insert a title`,
  'link.child.empty': `Please insert an uri`,
  'link.note.empty': `Please insert a note`,
  'save.link.error': `Error when saving`,
  'save.link.success': 'Save with success'
}