import gql from 'graphql-tag';
import { CARD_FRAGMENT, CARDS_FRAGMENT } from './fragment';

export const ME = gql`
  query Me {
    me {
      id
      email
      codeSpark
    }
  }
`;

export const CARD_WALLET_FRAGMENT = gql`
  query AllCardList($page: Int, $size: Int, $filter: CardFilter) {
    allCardList(page: $page, size: $size, filter: $filter) {
      data {
        ...CardsInfo
      }
      pagination {
        size
        page
        totalPage
        totalelements
      }
    }
  }
  ${CARDS_FRAGMENT}
`;

export const CARD_WALLET_NOT_VIEWED = gql`
  query AllCardNotViewedList($page: Int, $size: Int, $filter: CardFilter) {
    allCardListNotViewed(page: $page, size: $size, filter: $filter) {
      data {
        id
        notViewedBo
      }
      pagination {
        size
        page
        totalPage
        totalelements
      }
    }
  }
`;

export const CARD_LIST_BY_COMPANY = gql`
  query CardListByCompany($companyId: Int!, $page: Int, $size: Int) {
    cardListByCompany(companyId: $companyId, page: $page, size: $size) {
      data {
        ...CardsInfo
      }
      pagination {
        size
        page
        totalPage
        totalelements
      }
    }
  }
  ${CARDS_FRAGMENT}
`;

export const CARD_BY_ID = gql`
  query CardById($id: ID!) {
    cardById(id: $id) {
      ...CardInfo
    }
  }
  ${CARD_FRAGMENT}
`;

export const CARD_BY_TR_ID = gql`
  query CardTrByTreezorId($cardTrId: ID!) {
    cardTrByTreezorId(cardTrId: $cardTrId)
  }
`;
export const GET_CARD_EXPORT = gql`
  query CardsExport($format: FileType!) {
    cardsExport(format: $format)
  }
`;
