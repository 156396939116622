import gql from 'graphql-tag';

const CHECK_CONNECTION = gql`
  query CheckConnection {
    checkConnection
  }
`;

const INFORMATION_TREEZOR = gql`
  query InformationTreezor(
    $id: ID!
    $type: String!
    $page: Int
    $size: Int
    $createdDateFrom: DateTime
    $createdDateTo: DateTime
  ) {
    informationTreezor(
      id: $id
      type: $type
      page: $page
      size: $size
      createdDateFrom: $createdDateFrom
      createdDateTo: $createdDateTo
    )
  }
`;

const ACCOUNT = gql`
  query Account($id: ID!) {
    Account(id: $id) {
      id
      person {
        id
        company {
          id
          persons {
            id
            type
            name
            firstName
            address
            city
            postCode
            percent
            employeeType
            isPersonHosted
            account {
              id
              email
              phone
              kycReview
              kycLevel
              kycReviewComment
              kycRequested
              treezorUserState
            }
            documents {
              id
              uuid
              filename
              filetype
              typeId
              treezorDocumentId
              type {
                id
                name
                isProofOfResidence
              }
              url
            }
          }
        }
      }
    }
  }
`;

const ALLS_ACCOUNTS = gql`
  query AllsBAccounts($pagination: Pagination, $filter: SBAccountFilter) {
    allsBAccounts(pagination: $pagination, filter: $filter) {
      data {
        id
        isActivated
        account {
          id
          treezorUserId
          person {
            id
            name
            firstName
            type
            company {
              id
              legalName
              legalForm
            }
          }
        }
      }
      pagination {
        size
        page
        totalelements
      }
    }
  }
`;

const IS_ACCOUNT_ACTIVE = gql`
  query SBankAccountDataActive($accountId: ID!) {
    sapheerBankAccountData(accountId: $accountId) {
      id
      isActivated
    }
  }
`;

const IS_COMAPNY_ACTIVE = gql`
  query CheckIfCompanyActive($companyId: ID!) {
    checkIfCompanyActive(companyId: $companyId)
  }
`;

const TOKEN_EXPIRED = gql`
  query IsTokenExpired {
    isTokenExpired
  }
`;
export {
  ACCOUNT,
  CHECK_CONNECTION,
  ALLS_ACCOUNTS,
  TOKEN_EXPIRED,
  IS_ACCOUNT_ACTIVE,
  IS_COMAPNY_ACTIVE,
  INFORMATION_TREEZOR,
};
