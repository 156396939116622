export default {
  '404.button': 'Return',
  'home.welcomeMessage': 'Welcome home',
  networkError: 'Erreur Connexion',
  'error.unknownError': `Error, please retry later or contact your admin`,
  'error.verifyPassword': 'Error when changing password',
  'label.numberOfLine': 'Number of lines',
  'label.of': 'of',
  'label.legalName': 'Company',
  'label.legalForm': 'Legal Form',
  'label.firstName': 'First Name',
  'label.name': 'Last Name',

  'input.birthPostCode': `Birth post code`,
  'input.birthCountry': `Birth country`,
  'input.placeOfBirth': `Place of birth`,
  'input.citizenShip': `Citizenship`,
  'input.isPersonHosted': `Person hosted`,

  'label.view': 'More',
  'label.action': 'Action',
  'person.leader': 'Leader',
  'person.shareholder': 'Shareholder',
  'person.invite': 'Member',
  'label.beneficiary': 'Beneficiary',
  'label.modality': 'Modality',
  'label.attachement': 'Attachment',
  'label.id': 'ID',
  'label.title': 'Title',
  'label.offer': 'Offer',
  'label.date': 'Date',
  'label.amount': 'Amount',
  'label.owner': 'Owner',
  'label.modalityType': 'In/Out',
  'toolbar.validate': 'Validate',
  'toolbar.delete': 'Delete',
  'label.price': 'price',
  'input.id': 'ID',
  'kyc.email': 'Email',
  'kyc.phone': 'Phone number',
  'kyc.name': 'Name',
  'kyc.firstName': 'Firstname',
  'input.address': 'Address',
  'input.city': 'City',
  'input.postCode': 'Post code',
  'input.save': 'Save',
  'kyb.legalName': 'Legal name',
  'kyb.legalForm': 'Legal forme',
  'error.notEmptyField': 'This Field cannot be empty',
  report: 'Report client',
  'label.type': 'Type',
  'kyc.error.data': 'Empty data, please verify the data field',
  'phone.error.data': 'The phone number field is required',
  'kyb.error.data': 'Empty data, please verify the data field',
  'account.update.success': 'account update with success',
  'account.create.success': 'account create with success',
  'account.validate.success': 'account validate with success',
  'account.delete.success': 'account delete with success',
  'signin.error': 'Please check your e-mail or password',
  'signin.error.access': "You don't have the right access",
  'error.nothingSelected': 'Please pick one choice',
  'button.search': 'Search',
  'label.search': 'Search',
  'network.error': 'Network error while uploading file',
  'click.link': 'Click to view document',
  'no.document': 'No document to display',
  'kyc.error.mail': 'Mail already exist in database',
  'input.account.id': 'Account Id',
  'transaction.modality.transfer': 'Transfer',
  'transaction.modality.payin': 'Pay In',
  'transaction.modality.payout': 'Pay Out',
  'transaction.modality.card': 'Card',
  'transaction.modality.cheque': 'Cheque',
  'transaction.modality.facture': 'Invoice',
  'title.account': 'Account',
  'kyb.treezorId': 'Treezor ID',

  'phoneNumber.invalid':
    'Invalid phone number: The phone number must start with 00 or +',
  'phone.empty': 'The phone number field is required',

  'error.uploadOnlyOneFile': 'Maximum file count reached',
  'error.uploadFileInvalid':
    'Invalid file type. Please choose an image or a pdf',
  'error.uploadTooLarge': 'File too large.',
  'error.uploadTooSmall': 'File too small.',

  URL_EMPTY: `Error when downloading avatar`,
  MEMBER_LIMIT_EXCEEDED: `You reach the number of member limit for your offer.`,
  EMAIL_EXIST: `This email address already exist.`,
  ACCOUNT_DISABLED: `Your account is disable or not been validated.Please contact your administrator`,
  SELF_TRANSFERT_TRANSACTION: `Self transfert is not authorized, please check the IBAN.`,
  BENEFICIARY_ALREADY_EXIST: `This beneficiary is already exist.`,
  REQUIRED_INPUTS_ERRORS: 'Please fullfil all needed informations.',
  TRANSACTION_CANNOT_CANCELED: `This transaction can no longer be reversed because the deadline has passed.`,
  USER_CANT_CREATE_TRANSACTION: `You do not have authorization to create a transaction.`,
  ACCEPT_INVITATION_AGAIN: 'You have already accept invitation.',
  STANDARD_CARD_ONLY_FOR_START_OFFER: `Your standart card is only for Start offer`,

  ACTIVATION_CARD_ERROR: `Card activation error`,
  CARD_3DS_ERRORS: ' 3DS Card error',
  TREEZOR_CREATION_CARD_ERROR: 'Error when creating card',
  ACCOUNT_NEED_TO_VALIDATE: `Your account need to be validate`,
  ACCOUNT_NEED_TO_VALIDATE_CARD: 'The account need to be validate',
  TREEZOR_CONVERSION_CARD_ERROR: `Error when converting virtual card to physical card`,

  TREEZOR_CARD_NOT_FOUND: `Treezor card not found`,
  CARD_ALREADY_ACTIVATED: `Card already activated`,
  LOCK_UNBLOCK_CARD_EROR: `Switch activation card option error.`,
  TREEZOR_UPDATE_CARD_OPTION_ERROR: `Switch  card option error.`,

  PHONE_NUMBER_REQUIRED: `The phone number is required`,
  CODE_INVALID: `This code is invalid`,
  SETTING_PIN_ERROR: `Error when implementing pin code`,
  CARD_NOT_FOUND: `Card not found in server`,
  BENEFICIARY_IBAN_ERROR: `Beneficiary iban error`,
  PIN_NOT_MATCH: `Pin not match`,
  PAIEMENT_OR_WITHDRAW_VALUE_ERROR: `The chosen value does not correspond to your offer.`,
  CARD_NOT_ACTIVE: `Please activate the card`,

  'card.not.treezorCardId': `The card don't have an ID from creator`,

  'card.currentPin': 'Current PIN',
  'currentPin.empty': `Please insert your current PIN`,
};
