export default {
  'home.welcomeMessage': 'Bienvenue',
  networkError: 'Network Error',
  'error.unknownError': `Erreur lors de l'execution de l'opération,veuillez réessayer, si l'erreur persiste, veuillez contactez votre admin`,
  'error.verifyPassword': 'Erreur lors de la vérification du mot de passe',
  '404.button': 'Retour',
  'label.numberOfLine': 'Nombre de lignes',
  'label.of': 'sur',
  'label.legalName': 'Entreprise',
  'label.legalForm': 'Forme',
  'label.firstName': 'Nom',
  'label.name': 'Prénoms',
  'label.view': 'Détails',
  'label.action': 'Action',
  'label.beneficiary': 'Bénéficiaire',
  'person.leader': 'Gérant',
  'person.shareholder': 'Actionnaire',
  'person.invite': 'Membre',
  'label.id': 'ID',
  'label.title': 'Titre',
  'label.offer': 'Offre',
  'label.date': 'Date',
  'label.amount': 'Montant',
  'label.owner': 'Emetteur',
  'label.modality': 'Modalité',
  'label.attachement': 'Justificatif',
  'label.modalityType': 'Entrant/Sortant',
  'toolbar.validate': 'Valider',
  'toolbar.delete': 'Supprimer',
  'label.price': 'prix',
  'input.id': 'ID',
  'kyc.email': 'Adresse mail',
  'kyc.phone': 'Numéro de téléphone',
  'kyc.name': 'Nom',

  'input.birthPostCode': `Code postal`,
  'input.birthCountry': `Pays de naissance`,
  'input.placeOfBirth': `Ville de naissance`,
  'input.citizenShip': `Citoyenneté`,
  'input.isPersonHosted': `La personne est hébergé(e)`,

  'kyc.firstName': 'Prénoms',
  'input.address': 'Adresse',
  'input.city': 'Ville',
  'input.postCode': 'Code postal',
  'input.save': 'Enregistrer',
  'kyb.legalName': 'Raison sociale',
  'kyb.legalForm': 'Forme Juridique',
  'error.notEmptyField': 'Ce champ ne peut être vide',
  report: 'Reporter client',
  'click.link': 'Cliquez pour voir le document',
  'label.type': 'Type',
  'kyc.error.data': 'Présence champ vide, veuillez vérifier les données saisis',
  'phone.error.data': 'Le numéro de téléphone est requis',
  'kyb.error.data': 'Présence champ vide, veuillez vérifier les données saisis',
  'account.update.success': 'Compte mis à jour avec succès',
  'account.create.success': 'Compte créé avec succès',
  'account.validate.success': 'Compte validé avec succès',
  'account.delete.success': 'Compte supprimé avec succès',
  'signin.error':
    'Vérifiez si votre e-mail ou votre mot de passe est bien correct',
  'signin.error.access': "Vous n'avez pas le bon accès",
  'network.error': "Erreur lors de l'upload du fichier",
  'error.nothingSelected': 'Veuillez choisir une option',
  'button.search': 'Rechercher',
  'label.search': 'Rechercher',
  'no.document': 'Pas de document à afficher',
  'kyc.error.mail': 'Le mail existe déjà dans la base de donnée',
  'input.account.id': 'Id du compte',
  'transaction.modality.transfer': 'Transfert',
  'transaction.modality.payin': 'Virement',
  'transaction.modality.card': 'Carte',
  'transaction.modality.payout': 'Prélèvement',
  'transaction.modality.cheque': 'Chèque',
  'transaction.modality.facture': 'Facture',
  'title.account': 'Compte',
  'kyb.treezorId': 'ID Treezor',

  'phoneNumber.invalid': 'Le numéro de téléphone doit commencer par 00 ou + ',
  'phone.empty': 'Veuillez renseigner votre numéro de téléphone',

  'error.uploadOnlyOneFile': 'Veuillez choisir un seul fichier.',
  'error.uploadFileInvalid': 'Veuillez choisir un fichier de type image ou pdf',
  'error.uploadTooLarge': 'Fichier trop large.',
  'error.uploadTooSmall': 'Fichier trop petit.',

  URL_EMPTY: `Erreur lors du téléchargement de l'avatar`,
  MEMBER_LIMIT_EXCEEDED: `Vous avez atteint le nombre limit de membre pour votre offre.`,
  EMAIL_EXIST: `Cette adresse mail existe déjà.`,
  ACCOUNT_DISABLED: `Votre compte est désactivé ou n'a pas encore été validé.Veuillez contacter votre administrateur.`,
  SELF_TRANSFERT_TRANSACTION: `Le transfert vers le même compte n'est pas autorisé, veuillez vérifier l'IBAN.`,
  BENEFICIARY_ALREADY_EXIST: `Ce bénéficiaire existe déjà.`,
  REQUIRED_INPUTS_ERRORS: 'Veuillez remplir toutes les données requis',
  TRANSACTION_CANNOT_CANCELED: `Cette transaction ne peut plus être annulée car le délai est dépassé.`,
  USER_CANT_CREATE_TRANSACTION: `Vous n'avez pas l'autorisation de créer une transaction.`,
  ACCEPT_INVITATION_AGAIN: `Vous avez déjà accepté l'invitation.`,
  STANDARD_CARD_ONLY_FOR_START_OFFER: `La carte standart est seulement pour l'offre start`,

  ACTIVATION_CARD_ERROR: `Erreur lors de l'activation de la carte`,
  CARD_3DS_ERRORS: 'Erreur lors de la mise en place du 3DS',
  TREEZOR_CREATION_CARD_ERROR: 'Erreur lors de la création de la carte',
  ACCOUNT_NEED_TO_VALIDATE: `Votre compte doit être validé`,
  ACCOUNT_NEED_TO_VALIDATE_CARD: 'Le compte doit être validé',
  TREEZOR_CONVERSION_CARD_ERROR: `Erreur lors de la création de la carte physique à partir du carte virtuel`,

  TREEZOR_CARD_NOT_FOUND: `Impossible de trouver la carte chez le fournisseur`,
  CARD_ALREADY_ACTIVATED: `La carte est déjà activer`,
  LOCK_UNBLOCK_CARD_EROR: `Erreur lors du changement d'option activation du carte`,
  TREEZOR_UPDATE_CARD_OPTION_ERROR: `Erreur lors du changement d'option du carte`,

  PHONE_NUMBER_REQUIRED: `Le numéro téléphone est requis`,
  CODE_INVALID: `Votre code n'est pas valide`,
  SETTING_PIN_ERROR: `Erreur lors de la mise en place du code pin`,
  CARD_NOT_FOUND: `La carte n'existe pas dans le serveur`,
  BENEFICIARY_IBAN_ERROR: `Erreur lors de l'iban du bénéficiaire`,
  PIN_NOT_MATCH: `Le code pin ne correspond pas à votre code pin actuel`,
  PAIEMENT_OR_WITHDRAW_VALUE_ERROR: `La valeur choisie ne correspond pas à votre offre.`,
  CARD_NOT_ACTIVE: `Veuillez activer la carte`,
  'card.not.treezorCardId': `La carte ne dispose pas d'identifiant chez le créateur`,

  'card.currentPin': 'Pin actuel',
  'currentPin.empty': `Veuillez remplir votre code pin actuel`,
};
