import { useLazyQuery } from '@apollo/react-hooks';
import React, { FC, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import NoConnection from './components/Auth/CheckConnection/NoConnection';
import loadman from './components/Layout/Loadman/Loadman';
import { CHECK_CONNECTION } from './graphql/account/query';
import { CheckConnection } from './graphql/account/types/CheckConnection';

const SignIn = loadman(import('./components/Auth/SignIn/SignIn'));
const PageNotFound = loadman(import('./components/404'));
const Layout = loadman(import('./components/Layout/Layout'));

const Routes: FC = () => {
  const CONNEXION_MAX = 5;
  const [countConn, setCountConn] = useState(0);
  const [getCon, { error }] = useLazyQuery<CheckConnection>(CHECK_CONNECTION, {
    onError: (error) => {
      setCountConn(countConn + 1);
    },
  });

  useEffect(() => {
    if (countConn == 0) {
      getCon();
    } else if (countConn < CONNEXION_MAX) {
      setTimeout(function () {
        getCon();
      }, 2000);
    }
  }, [countConn]);
  if (error && countConn === CONNEXION_MAX) {
    const { networkError } = error;
    if (networkError && networkError.message) {
      return <NoConnection />;
    }
  }
  return (
    <Switch>
      <Route exact={true} path="/login" component={SignIn} />
      <Route exact={true} path="/404" component={PageNotFound} />
      <Route path="/" component={Layout} />
    </Switch>
  );
};

export default Routes;
