export default {
  'services.grid.noData': `Vous n'avez pas encore de service`,
  'service.logo.empty': `Veuillez insérer un logo à fond sombre`,
  'service.logo.dark.empty': `Veuillez insérer un logo à fond clair`,
  'service.name.empty': `Veuillez ajouter un nom`,
  'service.url.empty': `Veuillez ajouter un url`,
  'category.ids.not.enough': 'Veuillez ajouter au moins une catégorie',
  'service.contents.empty': `Veuillez ajouter le contenu`,
  'service.description.empty': `Veuillez ajouter une description`,
  'service.color1.empty': `Veuillez ajouter un couleur`,
  'service.color2.empty': `Veuillez ajouter une deuxième couleur`,
  CATEGORY_IDS_CANNOT_BE_EMPTY: `Veuillez fournir au moins une catégorie au plus`,
  SERVICEPLUS_ID_CANNOT_BE_NULL: `L'identifiant du service est obligatoire`,
  SERVICE_PLUS_NOT_FOUND: `Service plus introuvable`,
  ID_OR_CONSANT_CODE_REQUIRED: `Veuillez ajouter une identifiant`,
  'save.category.error': `Erreur lors de l'enregistrement de la catégorie`,
  'save.service.error': `Erreur lors de l'enregistrement de la service`,

  'delete.link.success': `Partenaire supprimé avec succès`,
  'delete.link.error': `Erreur lors de la suppression`,
  'link.title.empty': `Veuillez ajouter un titre`,
  'link.child.empty': `Veuillez ajouter un url`,
  'link.note.empty': `Veuillez ajouter une note`,
  'save.link.error': `Erreur lors de l'enregistrement`,
  'save.link.success': 'Mise à jour effectuée avec succès'
}