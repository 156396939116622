export default {
  'sapheer.subscription.checkBox': 'Subscription',
  'sapheer.subscriptionNotOk.checkBox': 'Catch-up',
  'sapheer.frais.checkBox': 'Withdrawal',
  'beneficiary.creditorName': 'Creditor Name',
  'transaction.grid.noData': 'No transaction to display',
  'transaction.title': 'Title',
  'transaction.dateTransaction': 'Execution date',
  'transaction.amount': 'Amount',
  'transaction.currency': 'Currency',
  'transaction.modalityType': 'IN/OUT',
  'transaction.transactionState': 'State',
  'transaction.companyName': 'Company Name',
  'transaction.treezorTransactionId': 'ID in Treezor',
  'transaction.walletbic': 'BIC',
  'transaction.walletIban': 'IBAN',
  'transaction.search.noResult': 'No transaction find',
  'title.transaction.details': 'Transaction Details',
  'related.transaction.account': 'Account Related',
  'related.transaction.wallet': 'Wallet Related',
  'transaction.document.error': 'Error while trying ro get document url',
  'transaction.dateCreateTransaction': 'Creation Date',
  'related.transaction.beneficiary': 'Beneficiary',
  'beneficiary.name': 'Beneficiary Name',
  'beneficiary.treezorUserId': 'Beneficiary Treezor user Id',
  'beneficiary.iban': 'IBAN',
  'beneficiary.bic': 'BIC',
  'beneficiary.walletId': 'Wallet Id',
  'beneficiary.companyName': 'Company Name',
  'label.transactionState': 'State',
  'dialog.create.virement.iban': 'IBAN',
  'dialog.create.virement.details': 'Title',
  'dialog.create.virement.title': 'Title',
  'dialog.create.virement.title.SMART_OFFER': 'Smart offer',
  'dialog.create.virement.title.PREMIUM_OFFER': 'Premium offer',
  'dialog.create.virement.title.CORPORATE_OFFER': 'Corporate offer',
  'dialog.create.virement.title.WITHDRAWAL_FEE': 'Withdrawal fee',
  'dialog.create.virement.title.PAYIN_FEE': 'Payin fee',
  'dialog.create.virement.title.PAYOUT_FEE': 'Payout fee',
  'dialog.create.virement.title.SERVICE_PLUS': 'Service plus fee',
  'dialog.create.virement.title.CLOSE': 'Close fee',
  'dialog.create.virement.title.JUDICAL_REQUISITION':
    'Judicial requisition fee',
  'dialog.create.virement.title.NOTICE_HOLDER':
    'Notice to third party holder fee',
  'dialog.create.virement.title.OPPOSITION_HOLDER':
    'Opposition to third party holder fee',
  'dialog.create.virement.title.SEIZYRE_BY_BAILIFF': 'Seizure by bailiff fee',
  'dialog.create.virement.title.INTERVENTION_COMMISSION':
    'Intervention commission fee',
  'dialog.create.virement.title.OTHER': 'Other',
  'dialog.create.virement.title.subscription': 'offer',
  'dialog.create.virement.title.CARD_LOSS': 'Card fee - Loss / Theft',
  'dialog.create.virement.title.CREATE_CARD': 'Card fee - Creation',
  'dialog.create.virement.details.amount': 'Amount',
  'dialog.create.prelevement.emitter': 'Emitter',
  'dialog.create.virement.beneficiary': 'Beneficiary',
  'dialog.create.transaction.type': 'Type',
  'dialog.button.cancel': 'Cancel',
  'dialog.button.create': 'Create',
  'toolbar.button.addTransaction': 'Transaction',
  'dialog.createTransaction': 'Creating a transaction',
  'transaction.iban.notValid': 'IBAN code is invalid',
  'transaction.title.empty': 'The transfer title is required',
  'transaction.amount.empty': 'The amount of transaction is required',
  'transaction.amount.nan': 'Amount not a number',
  'transaction.amount.min':
    'The amount of the transaction must be more than 1 Euro.',
  'transaction.virement.create.success': 'New transaction add with success.',
  'transaction.virement.confirm':
    'Do you really want to create this transaction?',
  'transaction.virement.create.error': 'Error while creating transfer.',
  'transaction.virement.create.error.spark_beneficiary_not_configured':
    'Please configure your beneficiary.',
  'dialog.create.virement.txtype.ACCOUNT_OFFER': 'Account offer',
  'dialog.create.virement.txtype.STANDARD': 'Standart',
  'dialog.create.virement.txtype.PAYIN_FEE': 'Sapheer - Fee',
  'dialog.create.virement.txtype.SAPHEER_ABONNEMENT': 'Sapheer - Subscription',
  'dialog.create.virement.txtype.ATM_FEE': 'ATM fee',
  'dialog.create.virement.txtype.CARD_FEE': 'Card fee',
  'dialog.create.virement.txtype.CARD_EXCESS_FEE': 'Card excess fee',
  'dialog.create.virement.txtype.DEPOSIT_CAPITAL': 'Deposit capital',
  'dialog.create.virement.txtype.SAPHEER_OTHER': 'Sapheer - Other',
  'dialog.create.virement.txtype.empty': 'Please select a type of transaction',

  'transaction.payout.type': 'Payin Fee',
  'transaction.payout.abonnement': 'Subscription',
  'transaction.payout.refund': 'Re-fund',
  'transaction.modality.cheque': 'Cheque',
  'transaction.label.card.in': 'Topup',
  'transaction.label.card.out': 'WithDrawal',
  'transaction.label.card.payment': 'Online payment',
  'transaction.label.facture': 'Subscription',
  'transaction.label.modality.payin': 'Wallet to Wallet',

  'toolbar.button.export': 'Export',
  'dialog.exportTransaction': 'Export Transaction',

  'export.startDate.empty': 'Date start empty',
  'export.endDate.empty': 'Date end empty',
  'toolbar.button.filter': 'Filter',
  'dialog.filterTransaction': 'Filter transactions',
  'tx.filter.card.label': 'Card(Withdrawal - Topup - Payment)',
  'tx.filter.virement.label': 'Virement',
  'transaction.sepa.inst': 'Virement Inst',
  'tx.filter.transfert.label': 'Wallet to wallet',
  'tx.filter.topup.label': 'Topup',
  'tx.filter.card.payment.label': 'Payment online',
  'tx.filter.card.withdrawal.label': 'Withdrawal',

  'sapheer.beneficiary.checkBox': `Invoices`,
  'company.transaction.state': `Invoicing`,
  'transaction.same.iban': `Please check the two iban entered.`,
  'transaction.offer.error': 'Please add an offer',
  'input.walletId': 'Wallet ID',
  'transaction.payout.out': 'Payout',

  'transaction.card.command': 'Command carte',
  'transaction.card.deposit': 'Deposit capital',

  'transaction.payout.delete': 'Delete',
  'transaction.payout.delete.confirm': `Voulez-vous vraiment supprimer cette transaction ?`,
  'beneficiary.cancelled': 'Beneficiary Cancelled',
  'btn.beneficiary.cancelled': 'Cancel beneficiary',
  'attachment.deleted': 'Attachment removed',
  'phone.updated': 'Phone successfully updated',
  'dialog.create.virement.bic': 'BIC',
  'transaction.bic.error': 'Invalid BIC',
  'dialog.create.virement.name': 'Name',
  'transaction.name.error': 'Invalid name',
  'transaction.label.transactionProof': 'Transaction proof',

  'synchronize.success': 'Data synchronized with success',
  'transaction.codeMorInfo': `More info`,

  'transaction.state.termined': 'Valid',
  'transaction.state.scheduled': 'Future',
  'transaction.state.canceled': 'Canceled',
  'transaction.state.pending': 'Waiting',
  'transaction.state.rejected': 'Rejected',
  'transaction.state.revered': 'Revered',
  'transaction.state.declined': 'Refuse',
  'transaction.state.unvalitaded': 'To validate',
  'transaction.state.settled': 'Settled',
  'transaction.state.accepted': 'Accepted',
  'transaction.state.refund': 'Refunded',
  'transaction.state.cleared': 'Deleted',
  'transaction.state.blocked.by.treezor': 'Analyse',
  'transaction.state.is.fraud': 'Risk',
  VIREMENT_INST: 'Virement Inst',
};
