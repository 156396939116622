export default {
  'recall.response.rejected': 'Rejeté',
  'recall.response.accepted': 'Accepté',
  NOOR: 'The associated transaction (SCTR) was not received',
  ARDT: 'the associated transaction has already been returned',
  AC04: 'the associated account is closed',
  NOAS: `the beneficiary didn't answer`,
  CUST: `the beneficiary rejected the recall`,
  AM04: `the beneficiary has insufficient funds`,
  LEGL: `legal decision (frozen assets)`,
  'recall.status.PENDING': `En attente`,
  'recall.status.ACCEPTED': 'Accepté',
  'recall.status.REJECTED': 'Rejeté',
  'recall.status.CANCELED': 'Annulé',
  'recall.status.IN_ERROR': 'En erreur',
  'recall.status.PENDING_ANSWER_REQUESTED': 'En attente reponse',
  'recall.status.PENDING_PAYIN_REFUND_CREATED': 'En attente payin refund',
};
