import Authentication from './signin';
import Home from './home';
import Notification from './notification';
import Company from './company';
import Transaction from './transaction';
import Offer from './offer';
import Card from './card';
import Common from './common';
import Sidebar from './sidebar';
import Service from './service';
import Recall from './recall';

export default {
  ...Authentication,
  ...Home,
  ...Notification,
  ...Company,
  ...Transaction,
  ...Offer,
  ...Card,
  ...Common,
  ...Sidebar,
  ...Service,
  ...Recall,
};
