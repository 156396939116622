export default {
  'input.iban': 'Iban',
  'kyc.usSpecifiedPerson': `Je certifie que je ne suis pas une US Person`,
  'kyc.occupation': 'Occupation',
  'kyc.incomeRange': 'Revenue (K€)',
  'kyc.personalAssets': 'Patrimoine (K€)',
  'label.singupProgression': `Souscription`,
  'company.grid.noData': 'Pas de compte à afficher',
  'account.search.noResult': 'Pas de compte trouvé',
  'kyc.type': `Type`,
  'kyc.employee.type': `Type d'employé`,
  'email.error.data': 'Le champ adresse email est requis',
  'address.error.data': 'Le champ adresse est requis',
  'city.error.data': 'Le champ ville est requis',
  'name.error.data': 'Le champ nom est requis',
  'firstName.error.data': `Le champ prénom est requis`,
  'type.error.data': `Le champ type est requis`,
  'percent.error.data': `Le champ pourcentage est requis`,
  'employeeType.error.data': `Le type d'employé est requis`,
  'postCode.error.data': `Le code postal est requis`,
  'birthday.error.data': `La date de naissance est requise`,
  'placeOfBirth.error.data': `La ville de naissance est requis`,
  'birthCountry.error.data': `Le pays de naissance est requis`,
  'birthPostCode.error.data': `Le code postal du ville de naissance est requis`,
  'specifiedUSPerson.error.data': `La case à cocher est obligatoire`,
  'nationality.error.data': `La nationalité est requis`,
  'percent.not.valid': `Le pourcentage saisi n'est pas valide (Total Pourcentage < 100%)`,
  'title.member': 'Membre',
  'company.leader': 'Gérant',
  'company.mandatory': 'Mandataire',
  'company.actionnaire': 'Actionnaire',
  'not.achieved': 'Inconnu',
  'kyc.member': 'KYC ',
  'level.none': 'Vide',
  'level.pending': 'En cours',
  'level.regular': 'Moyen',
  'level.strong': 'Elevé',
  'level.refused': 'Refusé',
  'level.investigating': 'En Observation',
  'review.none': 'À valider',
  'review.pending': 'En cours',
  'review.validated': 'Validé',
  'review.refused': 'Refusé',
  'kyc.level': 'Niveau Kyc',
  'kyc.review': 'Revue du Kyc',
  'kyc.review.comment': 'Commentaire du revue',
  'kyc.request': 'Kyc requis/non requis',
  'kyc.requested': 'Document envoyé',
  'kyc.notRequested': 'Document à envoyer',
  'kyc.treezorUserState': 'Etat Treezor',
  'kyc.offer': 'Offre',
  'account.report.success': 'Demande de renvoi du document envoyée ',
  'account.report.error': 'Erreur lors de la demande de renvoi du document',
  'kyc.not.specified': 'Non spécifié',
  'kyc.treezorId': 'Id Treezor',

  'document.update.success': 'Document mis à jour avec succès',
  'company.add.doc.empty': `Veuillez ajouter deux pièces justificatives de l'entreprise`,
  'company.doc.edit.empty': 'Veuillez sélectionner un document',
  'company.update.doc.success': 'Mise à jour du document effectuée avec succès',
  'company.add.doc.success': 'Ajout du document effectué avec succès',

  'legalRegistrationNumber.empty': 'Veuillez fournir le SIRET de la société',
  'legalRegistrationDate.empty': `Veuillez fournir la date d'enregistrement de la société`,
  'country.empty': 'Veuillez fournir le pays de la société',
  'legalSector.empty': `Veuillez fournir le Secteur d'activité de la société`,
  'legalNumberOfEmployeeRange.empty': `Veuillez fournir le nombres d'employés de la société`,
  'legalNetIncomeRange.empty':
    'Veuillez fournir une fourchette de revenu net de la société',
  'legalAnnualTurnOver.empty': `Veuillez fournir le Chiffre d'affaires annuel de la société`,

  'personLists.quit.title': 'Quitter sans enregistrer',
  'personLists.quit.question':
    'Les modifications non sauvegardées seront perdues',
  'personLists.save': 'Enregistrer',
  'personLists.close': 'Fermer',
  'personLists.firstID': 'Première pièce d’identité',
  'personLists.proofOfAddress': 'Justificatif de domicile',
  'personLists.secondID': 'Seconde pièce d’identité',
  'personLists.hostID': "Pièce d'identité de l'hébergeur",
  'personLists.hostProofOfAddress': "Justificatif de domicile de l'hébergeur",
  'personLists.hostProofOfAttestation': `Attestation de l'hébergeur`,
  'personLists.documentReport':
    'Etes-vous sûr de vouloir reporter ce document ?',
  'personLists.report': 'Reporter',

  'cin.recto': `Carte d'Identité Nationale`,
  'cin.verso': `Carte d'Identité Nationale Verso (Optionnelle)`,

  'shareholder.type.none': `Non employé`,
  'shareholder.type.leader': 'Dirigeant',
  'shareholder.type.employee': 'Employé',

  'dialog.exportCompany': 'Exporter les entreprises',
  solde: 'Solde ',

  'identity.file.recto': `Pièce d'identité`,
  'identity.file.verso': `Pièce d'identité Verso (Optionnelle)`,
  'identity2.file.recto': `Vigilance complémentaire`,
  'identity2.file.verso': `Vigilance complémentaire Verso (Optionnelle)`,
  'identity3.file.recto': `Pièce d'identité de l'hébergeur`,
  'identity3.file.verso': `Pièce d'identité de l'hébergeur Verso (Optionnelle)`,
  'company.disable.message': `Voulez-vous vraiment supprimer cette entreprise ?`,
  'company.disable.account.message': `Voulez-vous vraiment supprimer ce compte ?`,
  'company.disable.account.title': `Supprimer`,
  'company.disable.title': `Supprimer cette entreprise`,
  'company.disable.account.success': ` Compte supprimé avec succès`,
  'company.disable.success': ` Entreprise supprimé avec succès`,
  'company.disable.account.error': ` Erreur lors de la suppression du compte`,
  'company.disable.error': ` Erreur lors de la suppression de cette entreprise`,

  'kyc.doc.delete.message': 'Voulez-vous vraiment supprimer ce document ?',

  'stepFour.file.urssaf': `Attestation URSSAF`,
  'stepFour.file.virement': `Virement entrant de 20€ provenant d'un compte au même nom`,
  'stepFour.file.orderProfessional': `Document justifiant le statut d'un Ordre professionnel`,
  'stepFour.file.insee': 'Avis INSEE',
  'stepFour.file.sirene': `Avis de situation SIRENE`,
  'stepFour.create.register': 'K-Bis de moins de 3 mois ',
  'stepFour.docProof': `Statuts de la société - Certifiés conformes`,
  'stepFour.exist.docProof': `Statuts certifiés conformes de moins d'un an`,

  'company.card.create': 'Cartes',
  'company.archive': 'Inactif',

  'signup.company.information': `Société`,
  'signup.company.leader': `Dirigeant`,
  'signup.company.actionary': `Actionnaires`,
  'signup.company.contract': `Contrat`,
  'signup.company.choice': `Offres`,
  'signup.company.finish': 'Terminé',
  'input.add': 'Ajouter',
  'kyc.percent': 'Pourcentage',
  'input.balance': 'Solde',

  'account.close.title': 'Clôturer',
  'account.close.message': 'Voulez-vous vraiment clôturer ce compte ?',
  'account.close.success': `Compte clôturé avec succès `,
  'account.close.error': 'Erreur lors de la clôture du compte',
  'company.readOnly': 'Limité',
  'company.freezed': 'Gelé',
  'input.country': 'Pays',

  'existing.company.creation': 'Création',
  'existing.company.registered': 'Immatriculée',
  'existing.company.deleted': 'Clôturée',

  'user.freezed.value': 'oui',
  'user.notfrezed.value': 'non',

  'user.freezed': 'Gelé',

  'company.count.transaction.payin': 'PayIn',
  'company.count.transaction.payout': 'PayOut',
  'company.count.transaction.paiements': 'Paiements',
  'company.count.transaction.withdrawal': 'Retraits',
  'company.count.transaction.topup': 'TopUp',

  'label.dateValidation': 'Validation',
};
