export default {
  'transaction.fraud': 'Risque',
  maintenance: 'Sapheer est en maintenance, revenez dans quelques minutes',
  'maintenance.title': `Maintenance en cours`,
  'maintenance.subtitle1': `Nous améliorons notre plateforme`,
  'maintenance.subtitle2': `Revenez dans quelques minutes`,
  'error.form.empty': 'Veuillez remplir le formulaire',
  'date.invalid': `La date saisie n'est pas valide`,
  'error.max.date': 'Veuillez saisir une date antérieur',
  'error.min.date': 'Veuillez saisir une date supérieur',
  'all.empty': 'Veuillez séléctionnez au moins un filtre',
  'error.uploadFileTooLarge':
    'La taille du fichier ne doit pas dépasser 10 Mbs',
  'card.grid.noData': 'Pas de carte à afficher',
  'url.notValid': `L'url n'est pas valide`,
  confirm: 'Confirmer',
  back: 'Retour',
  'label.search.criteria': 'Critère',
  LEGAL_NAME: 'Raison sociale',
  OFFER_NAME: 'Offre',
  TREEZOR_USER_PHYSIC_ID: 'User physique ID',
  TREEZOR_USER_MORALE_ID: 'User morale ID',
  TREEZOR_WALLET_ID: 'Wallet ID',
  TREEZOR_CARD_ID: 'Card ID',
  TITLE: 'Intitulé',
  MONTANTS: 'Montant',
  BENEFICIARY_NAME: 'Nom bénéficiaire',
  CARD_PERSON_NAME: 'Titulaire carte',
  TREEZOR_TRANSACTION_ID: 'Transaction ID',
  OTHER: 'Autre',
  'btn.validate': 'Valider',
  'btn.reset': 'Réinitialiser',
  'file.empty': 'Sélectionnez un fichier',
  'btn.file': 'Sélectionnez un fichier',
  'btn.upload': 'Ajouter',
  'btn.cancel': 'Annuler',
  'btn.delete': 'Supprimer',
  'btn.add': 'Ajouter',
  'btn.download': 'Télécharger',
  'data.not.found': `Données introuvables,  veuillez vérifier la véracité de l'information`,
  'statement.info.empty': 'Veuillez ajouter une date de début et une date fin',
  'synchronize.error': 'Erreur lors du synchronisation',
  'not.executed': `Erreur lors de l'éxecution`,
  'not.deleted': `Erreur lors de la suppression`,
  'no.hooks': `Erreur lors de la récuperation des webhooks`,
};
