export default {
  'offer.grid.noData': 'No offer to display',
  'offer.name': 'Offer name',
  'offer.price': 'Price',
  'offer.error.data': 'Please fill the price field',
  'offer.update.success': 'Offer updated',
  'offer.error.update': 'Error while updating offer',
  'toolbar.offer.details': 'Offer Details',
  'offer.virtualCard': `Virtual Card`,
  'offer.physicalCard': `Physical Card`,
  'offer.cardTransactions': `Trx/Card`,
  'offer.payins': `Pay in`,
  'offer.payouts': `Pay out`,
  'offer.cheque': `Cheque`,
  'offer.withdrawal': `Withdrawal`,
  'offer.smartAccess': `Users`,
  'offer.servicePlus': `Additional Service`,
  'offer.liveCard': `Live card`,
  'offer.smartDashboard': `Smart dashboard`,
  'offer.categorisationAuto': `Catégorisation auto`,
  'offer.numeric.error.data': 'Please insert a numeric value'
};
