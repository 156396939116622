export default {
  'not.title': 'Create Notification',
  'not.input.title': 'Title',
  'not.choice': 'Choose users to send',
  'not.type': 'Type',
  'not.company': 'Company',
  'not.user': 'Users',
  'not.label.title': 'Title',
  'not.file': 'File',
  'not.button.save': 'Create',
  'not.button.cancel': 'Cancel',
  'not.all': 'All',
  'not.all.companies': `All companies`,
  'not.all.users': `All users`,
  'notification.create.success': 'Notification created',
  'notification.create.error': 'Error while creating notification',

  FONCTIONNALITE: 'Fonctionality',
  MESSAGE: 'Messages',
  SERVICE: 'Services',
  'notification.grid.noData': 'No notification to display',
  'notification.search.noResult': 'No notification find',
};
