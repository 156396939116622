export default {
  'not.title': 'Création de Notification',
  'not.input.title': 'Titre',
  'not.choice': 'Choisissez à qui envoyer',
  'not.type': 'Type',
  'not.company': 'Entreprise',
  'not.user': 'Utilisateurs',
  'not.label.title': 'Titre',
  'not.file': 'Fichier',
  'not.button.save': 'Créer',
  'not.button.cancel': 'Annuler',
  'not.all': 'Tout',
  'not.all.companies': `Toutes les entreprises`,
  'not.all.users': `Tous les utilisateurs`,
  'notification.create.success': 'La notification a été créée',
  'notification.create.error': 'Erreur lors de la création de notification',
  FONCTIONNALITE: 'Fonctionnalité',
  MESSAGE: 'Message',
  SERVICE: 'Service',
  'notification.grid.noData': 'Listes des notifications vide',
  'notification.search.noResult': 'Pas de notification trouvée',
};
