import { StringMapInterface, IItems } from '../interface/CommonInterface';

export const BENEFICIARY_EFFECTIF = 25;

export enum VIEW {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum TREEZOR_USER {
  NOT_FREEZED = 0,
  FREEZED = 1,
}

export enum FREEZED_USER {
  NOT_FREEZED = 'user.notfrezed.value',
  FREEZED = 'user.freezed.value',
}

export enum CardState {
  UNLOCK = 0,
  LOCK = 1,
  LOST = 2,
  STOLEN = 3,
  DESTROYED = 4,
}

export enum CardType {
  VIRTUAL = 0,
  PHYSICAL = 1,
}

export enum TreezorDocType {
  ADDRESS_PROOF_DOC = 'ADDRESS_PROOF_DOC',
  VIGILANCE_SUP_DOC = 'VIGILANCE_SUP_DOC',
  COMPANY_REGISTRATION = 'COMPANY_REGISTRATION',
  IDENTITY_DOC = 'IDENTITY_DOC',
  HOSTING_PROOF_IDENTITY = 'HOSTING_PROOF_IDENTITY',
  HOSTING_PROOF_ADDRESS = 'HOSTING_PROOF_ADDRESS',
  HOSTING_PROOF_ATTESTATION = 'HOSTING_PROOF_ATTESTATION',
  COMPANY_STATUS = 'COMPANY_STATUS',
}

export enum CompanyCriteria {
  OTHER = 'OTHER',
  LEGAL_NAME = 'LEGAL_NAME',
  OFFER_NAME = 'OFFER_NAME',
  TREEZOR_USER_PHYSIC_ID = 'TREEZOR_USER_PHYSIC_ID',
  TREEZOR_WALLET_ID = 'TREEZOR_WALLET_ID',
  TREEZOR_USER_MORALE_ID = 'TREEZOR_USER_MORALE_ID',
}

export enum CARD_TYPE_ID {
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
}

export enum CIN_ENUM {
  RECTO = 'RECTO',
  VERSO = 'VERSO',
}

export enum MESSAGE_TYPE {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum PERSON_TYPE {
  LEADER = 1,
  SHAREHOLDER = 2,
  LEADER_MENDATOR = 3,
  EMPLOYE = 4,
}

export enum COLOR_GRAPH {
  IN = '#00A878',
  OUT = '#FB4766',
}

export enum NOTIFICATION_TYPE {
  MESSAGE = 'MESSAGE',
  SERVICE = 'SERVICE',
  FONCTIONNALITE = 'FONCTIONNALITE',
}

export enum CURRENCY {
  EUR = 'EUR',
}

export const CURRENCY_SYMBOL: StringMapInterface = {
  EUR: '€',
  USD: '$',
  GBP: 'GBP',
};

export const CURRENCY_VALUE: StringMapInterface = {
  '€': 'EUR',
  $: 'USD',
  GBP: 'GBP',
};
export enum DATE_TIME_FORMAT {
  DATE_ONLY = 'YYYY-MM-DD',
}

export enum SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum MODALITY_TYPE {
  OUT = 1,
  IN = 0,
}
export enum EXISTING_COMPANY {
  CREATED = 'existing.company.creation',
  REGISTERED = 'existing.company.registered',
}

export const MODALITY = [
  { modality: 0, value: 'tx.filter.transfert.label' },
  { modality: 1, value: 'tx.filter.virement.label' },
  { modality: 2, value: 'transaction.modality.payout' },

  { modality: 3, value: 'transaction.modality.card' },

  { modality: 4, value: 'transaction.modality.cheque' },
  { modality: 5, value: 'transaction.modality.facture' },

  { modality: 6, value: 'transaction.payout.abonnement' },
  { modality: 7, value: 'transaction.payout.type' },

  { modality: 8, value: 'transaction.payout.refund' },

  { modality: 9, value: 'transaction.label.card.payment' },
  { modality: 10, value: 'transaction.label.card.out' },
  { modality: 12, value: 'transaction.label.card.in' },
  { modality: 13, value: 'transaction.card.command' },
  { modality: 14, value: 'transaction.card.deposit' },
];

export const PERSONTYPE = [
  { type: 1, value: 'company.leader' },
  { type: 3, value: 'company.mandatory' },
  { type: 2, value: 'company.actionnaire' },
  { type: 4, value: 'person.invite' },
];

export enum ShareholderType {
  NONE = 0,
  LEADER = 1,
  EMPLOYE = 2,
}

export enum KYCLevel {
  NONE = 0,
  PENDING = 1,
  REGULAR = 2,
  STRONG = 3,
  REFUSED = 4,
  INVESTIGATING = 5,
}

export enum KYCReview {
  NONE = 0,
  PENDING = 1,
  VALIDATED = 2,
  REFUSED = 3,
}

export const KYCLEVEL = [
  { level: KYCLevel.NONE, value: 'level.none' },
  { level: KYCLevel.PENDING, value: 'level.pending' },
  { level: KYCLevel.REGULAR, value: 'level.regular' },
  { level: KYCLevel.STRONG, value: 'level.strong' },
  { level: KYCLevel.REFUSED, value: 'level.refused' },
  { level: KYCLevel.INVESTIGATING, value: 'level.investigating' },
];

export const KYCREVIEW = [
  { level: KYCReview.NONE, value: 'review.none' },
  { level: KYCReview.PENDING, value: 'review.pending' },
  { level: KYCReview.VALIDATED, value: 'review.validated' },
  { level: KYCReview.REFUSED, value: 'review.refused' },
];

export enum SignupProgression {
  INFORMATION = 3,
  LEADER = 4,
  ACTIONARY = 5,
  CONTRACT = 6,
  FINISH = 7,
  CHOISE = 0,
}

export const SIGNUPPROGRESSION = [
  { level: SignupProgression.INFORMATION, value: 'signup.company.information' },
  { level: SignupProgression.LEADER, value: 'signup.company.leader' },
  { level: SignupProgression.ACTIONARY, value: 'signup.company.actionary' },
  { level: SignupProgression.CONTRACT, value: 'signup.company.contract' },
  { level: SignupProgression.FINISH, value: 'signup.company.finish' },
  { level: SignupProgression.CHOISE, value: 'signup.company.choice' },
];

export enum typeCompanyValue {
  CREATION = 0,
  REGISTERED = 1,
  DELETED = 2,
  ISARCHIVE = 3,
  READONLY = 4,
  ISFREEZED = 5,
}
export enum deletedValue {
  NOT_DELETED = 0,
  DELETED = 1,
}
export const NOTACHIEVED = 'not.achieved';

export enum MODALITY_TYPE_TEXT {
  OUT = 'SORTANT',
  IN = 'ENTRANT',
}
export enum TRANSACTION_MODALITY_TYPE {
  IN = 0,
  OUT = 1,
}

export enum SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum SORTFIELD {
  ID = 'id',
  FIRST_NAME = 'firstName',
  NAME = 'name',
  COMPANY_NAME = 'companyName',
  TITLE = 'title',
  MODALITY = 'modality',
  DATE = 'date',
  AMOUNT = 'amount',
  STATE = 'state',
}

export const CUSTOM_SYMBOL = '+';

export const DEFAULT_TR_INTO_GRID = 50;

export enum TRANSACTION_STATE {
  UNVALIDATED = 'UNVALIDATED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELED',
  TERMINATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  SCHEDULED_END = 'SCHEDULED_END',
  DECLINED = 'DECLINED',
  REVERDED = 'REVERDED',
  SETTLED = 'SETTLED',
  ACCEPTED = 'ACCEPTED',
  REFUND = 'REFUND',
  CLEARED = 'CLEARED',
  BLOCKED_BY_TREEZOR = 'BLOCKED_BY_TREEZOR',
  IS_FRAUD = 'IS_FRAUD',
}

export enum FILE_TYPE {
  pdf = 'application/pdf',
  csv = 'application/csv',
  xlsx = 'application/xlsx',
}

export enum INST_BOLT {
  bolt = '⚡',
}

export enum TREEZOR_INFORMATION {
  USER = 'USER',
  USER_EMAIL = 'USER_EMAIL',
  BENEFICIARY = 'BENEFICIARY',
  CARD = 'CARD',
  WALLET = 'WALLET',
  DOCUMENT = 'DOCUMENT',
  TRANSFER = 'TRANSFER',
  PAYOUT = 'PAYOUT',
  PAYIN = 'PAYIN',
  CARD_TRANSACTION = 'CARD_TRANSACTION',
  TRANSACTION = 'TRANSACTION',
  WALLET_TRANSACTION = 'WALLET_TRANSACTION',
  BALANCE = 'BALANCE',
  PAYIN_REFUND = 'PAYIN_REFUND',
  PAYOUT_REFUND = 'PAYOUT_REFUND',
  TRANSFER_REFUND = 'TRANSFER_REFUND',
  RECALL = 'RECALL',
  RECALLS = 'RECALLS',
  SCRT_INST = 'SCRT_INST',
}

export const treezorIitems: IItems[] = [
  { id: TREEZOR_INFORMATION.BALANCE, label: 'Balance' },
  { id: TREEZOR_INFORMATION.BENEFICIARY, label: 'Beneficiary' },
  { id: TREEZOR_INFORMATION.CARD, label: 'Card' },
  { id: TREEZOR_INFORMATION.CARD_TRANSACTION, label: 'Card Transactions' },
  { id: TREEZOR_INFORMATION.DOCUMENT, label: 'Document' },
  { id: TREEZOR_INFORMATION.PAYIN, label: 'Payin' },
  { id: TREEZOR_INFORMATION.SCRT_INST, label: 'Payin Inst' },
  { id: TREEZOR_INFORMATION.PAYIN_REFUND, label: 'Payin Refund' },
  { id: TREEZOR_INFORMATION.PAYOUT, label: 'Payout' },
  { id: TREEZOR_INFORMATION.PAYOUT_REFUND, label: 'Payout Refund' },
  { id: TREEZOR_INFORMATION.TRANSACTION, label: 'Transaction' },
  { id: TREEZOR_INFORMATION.TRANSFER, label: 'Transfer' },
  { id: TREEZOR_INFORMATION.TRANSFER_REFUND, label: 'Transfer Refund' },
  { id: TREEZOR_INFORMATION.USER, label: 'User' },
  { id: TREEZOR_INFORMATION.USER_EMAIL, label: 'User Email' },
  { id: TREEZOR_INFORMATION.WALLET, label: 'Wallet' },
  { id: TREEZOR_INFORMATION.WALLET_TRANSACTION, label: 'Wallet Transactions' },
  { id: TREEZOR_INFORMATION.RECALL, label: 'Recall' },
  { id: TREEZOR_INFORMATION.RECALLS, label: 'Recalls' },
];

export const hookIitems: IItems[] = [
  { id: 'cardtransaction.create', label: 'cardtransaction.create' },
  { id: 'payin.create', label: 'payin.create' },
  { id: 'payin.update', label: 'payin.update' },
  { id: 'payin.cancel', label: 'payin.cancel' },
  { id: 'payinrefund.create', label: 'payinrefund.create' },
  { id: 'payinrefund.update', label: 'payinrefund.update' },
  { id: 'payout.create', label: 'payout.create' },
  { id: 'payout.update', label: 'payout.update' },
  { id: 'payout.cancel', label: 'payout.cancel' },
  { id: 'payoutRefund.create', label: 'payoutRefund.create' },
  { id: 'payoutRefund.update', label: 'payoutRefund.update' },
  { id: 'payoutRefund.cancel', label: 'payoutRefund.cancel' },
  { id: 'sepa.return_sctr', label: 'sepa.return_sctr' },
  { id: 'sepa.reject_sddr_core', label: 'sepa.reject_sddr_core' },
  { id: 'sepa.reject_sddr_b2b', label: 'sepa.reject_sddr_b2b' },
  { id: 'sepa.reject_sdde', label: 'sepa.reject_sdde' },
  { id: 'sepa.return_sddr', label: 'sepa.return_sddr' },
  { id: 'transfer.create', label: 'transfer.create' },
  { id: 'transfer.update', label: 'transfer.update' },
  { id: 'transfer.cancel', label: 'transfer.cancel' },
  { id: 'transferrefund.create', label: 'transferrefund.create' },
  { id: 'transferrefund.update', label: 'transferrefund.update' },
  { id: 'transferrefund.cancel', label: 'transferrefund.cancel' },
  { id: 'user.create', label: 'user.create' },
  { id: 'user.update', label: 'user.update' },
  { id: 'user.cancel', label: 'user.cancel' },
  { id: 'user.kycreview', label: 'user.kycreview' },
  { id: 'user.kycrequest', label: 'user.kycrequest' },
];

export enum WebhookTypes {
  BALANCE_UPDATE = 'balance.update',
  BANK_ACCOUNT_CREATE = 'bankaccount.create',
  BANK_ACCOUNT_UPDATE = 'bankaccount.update',
  BANK_ACCOUNT_CANCEL = 'bankaccount.cancel',
  BENEFICIARY_CREATE = 'beneficiary.create',
  BENEFICIARY_UPDATE = 'beneficiary.update',
  CARD_REQUEST_PHYSICAL = 'card.requestphysical',
  CARD_CREATE_VIRTUAL = 'card.createvirtual',
  CARD_CONVERT_VIRTUAL = 'card.convertvirtual',
  CARD_CHANGE_PIN = 'card.changepin',
  CARD_ACTIVATE = 'card.activate',
  CARD_RENEW = 'card.renew',
  CARD_REGENERATE = 'card.regenerate',
  CARD_UPDATE = 'card.update',
  CARD_LIMITS = 'card.limits',
  CARD_OPTIONS = 'card.options',
  CARD_SET_PIN = 'card.setpin',
  CARD_UNBLOCK_PIN = 'card.unblockpin',
  CARD_LOCK_UNLOCK = 'card.lockunlock',
  CARD_DIGITALIZATION_UPDATE = 'cardDigitalization.update',
  CARD_TRANSACTION_CREATE = 'cardtransaction.create',
  COUNTRY_GROUP_CREATE = 'countryGroup.create',
  COUNTRY_GROUP_UPDATE = 'countryGroup.update',
  COUNTRY_GROUP_CANCEL = 'countryGroup.cancel',
  DOCUMENT_CREATE = 'document.create',
  DOCUMENT_UPDATE = 'document.update',
  DOCUMENT_CANCEL = 'document.cancel',
  MANDATE_CREATE = 'mandate.create',
  MANDATE_SIGN = 'mandate.sign',
  MANDATE_CANCEL = 'mandate.cancel',
  MCC_GROUP_CREATE = 'mccGroup.create',
  MCC_GROUP_UPDATE = 'mccGroup.update',
  MCC_GROUP_CANCEL = 'mccGroup.cancel',
  ONE_CLICKCARD_CREATE = 'oneclickcard.create',
  ONE_CLICKCARD_UPDATE = 'oneclickcard.update',
  ONE_CLICKCARD_CANCEL = 'oneclickcard.cancel',
  PAYIN_CREATE = 'payin.create',
  PAYIN_UPDATE = 'payin.update',
  PAYIN_CANCEL = 'payin.cancel',
  PAYIN_REFUND_CREATE = 'payinrefund.create',
  PAYIN_REFUND_UPDATE = 'payinrefund.update',
  PAYIN_REFUND_CANCEL = 'payinrefund.cancel',
  PAYOUT_CREATE = 'payout.create',
  PAYOUT_UPDATE = 'payout.update',
  PAYOUT_CANCEL = 'payout.cancel',
  PAYOUT_REFUND_CREATE = 'payoutRefund.create',
  PAYOUT_REFUND_UPDATE = 'payoutRefund.update',
  PAYOUT_REFUND_CANCEL = 'payoutRefund.cancel',

  SEPA_RETURN_SCTR = 'sepa.return_sctr',

  SEPA_REJECT_SDDR_CORE = 'sepa.reject_sddr_core',
  SEPA_REJECT_SDDR_B2B = 'sepa.reject_sddr_b2b',

  SEPA_REJECT_SDDE = 'sepa.reject_sdde',
  SEPA_REJECT_SDDR = 'sepa.return_sddr',

  TRANSACTION_CREATE = 'transaction.create',
  TRANSFER_CREATE = 'transfer.create',
  TRANSFER_UPDATE = 'transfer.update',
  TRANSFER_CANCEL = 'transfer.cancel',
  TRANSFER_REFUND_CREATE = 'transferrefund.create',
  TRANSFER_REFUND_UPDATE = 'transferrefund.update',
  TRANSFER_REFUND_CANCEL = 'transferrefund.cancel',
  USER_CREATE = 'user.create',
  USER_UPDATE = 'user.update',
  USER_CANCEL = 'user.cancel',
  USER_KYC_REVIEW = 'user.kycreview',
  USER_KYC_REQUEST = 'user.kycrequest',
  WALLET_CREATE = 'wallet.create',
  WALLET_UPDATE = 'wallet.update',
  WALLET_CANCEL = 'wallet.cancel',
  MERCHANT_ID_GROUP_CREATE = 'merchantIdGroup.create',
  KYCLIVENESS_CREATE = 'kycliveness.create',
  KYCLIVENESS_UPDATE = 'kycliveness.update',
}

export enum ROLE_INSIDE_SPARK {
  SPARK_STAGIAIRES = 'SPARK_STAGIAIRES_ROLES',
  SPARK_CDI = 'SPARK_CDI_ROLES',
}

export const CODE_REPONSE_BLOCKED_BY_TREEZOR = '160012';
export enum COLOR {
  VIREMENT_FROM_BO = 'blue',
  VIREMENT_BLOCKED_TREEZOR = 'red',
}

export const ATTACHMENT_LENGTH_EMPTY = 0;

export const TREEZOR_ERROR: StringMapInterface = {
  '16027': 'INCOHERENCE_KYC_LEVEL',
  '00001': 'Generic Error',
  '00002': 'A config file is needed',
  '00003': 'A database error has occured',
  '00005': 'An Exception has been raised',
  '00006': 'An Exception has been raised',
  '00007': 'The following argument(s is(are missing : %s',
  // '00007': 'The following argument %s must have one of these values : %s',
  '00010': 'The following argument(s must not be sent : %s',
  '000011': 'A configuration Exception has been raised',
  '000012': 'The filter given in the request is not available',
  '00013':
    'One filter given in the request is empty, you should provide a filter value at least',
  '00014':
    'One filter given in the request provides a not valid filter criteria : valid ones are >, >=, <, <=, like, in, ==, != ',
  '00015':
    'One filter given in the request provides a not valid filter criteria : the filter values should be compliant with their expected types ',
  '00016':
    'One filter of type array given in the request is not valid, it must contain at least one value',
  '00017':
    'One filter of type array given in the request is not valid, it must contain only scalar values',
  '00020':
    'One of the sort condition you provided in the sort parameter is not a valid field of the object',
  '00021': 'The field parameter of the request does not exists',
  '00022': 'Wrong format for %s',
  '00023': '%s must be in format YYYY-MM-DD',
  '00040': '%s must be in format YYYY-MM-DD or YYYY-MM-DD HH:MM:SS',
  '00024': '%s must be type boolean',
  '00025': 'Filters are in the worng format for at least one filter',
  '00026': 'Sort is in the worng format',
  '00027': 'The filter parameter of the request should be of type string',
  '28': "User's assets are freezed",
  '29': 'Email sending failed',
  '30': 'This service is not available.',
  '31': 'The same request is already running',
  '32': 'Log cleaning exception',
  '33': 'Too many arguments',
  '34': 'Time range invalid argument',
  '35': 'Not implemented',
  '36': 'A database error has occured',
  '37': 'A database error has occured',
  '38': 'An error has occurred. Your transaction will be processed asynchronously',
  '39': 'An amqp message is received, but somes informatations is missing',
  '40': 'This service is not implemented.',
  '41': 'At least one argument must be provided of this list',
  '42': 'The object is in final status',
  '43': 'The object does not exist',
  '1029': 'Non-existent client for the given user',
  '10000': 'Error Authentification',
  '10002': 'Slave Database Connection Error',
  '10003': 'Non Described Error',
  '10004': 'Access Token Error',
  '10005': 'Client Secret Retrieval Error',
  '10006': 'Database Connection Error',
  '10007': 'Invalid Content Verification Signature',
  '10008': 'Request ClientId differ from Oauth2 ClientId',
  '10009': 'Request UserId does not belong to Oauth2 ClientId',
  '10010': 'Request WalletId does not belong to Oauth2 ClientId',
  '10011': 'Request ClientId differ from Oauth2 ClientId.',
  '10012': 'User Canceled',
  '10013': 'Oauth2 userId differ from request UserId',
  '10014': 'Request WalletId does not belong to Oauth2 userId',
  '10015': 'UserId does not exists, or UserrId is Canceled',
  '10016': 'Error retrieveing Email Header and Footer',
  '10017': 'Error sending Email',
  '10020': 'Error with image',
  '10021': 'Error Generating Thumbnail',
  '10022': 'IP address not authorized',
  '10023': 'UserId is in pending state',
  '10024': 'JointUserId does not exists, or JointUserId is Canceled',
  '10025': 'UserId is in invalid state',
  '10026': 'JointUserId is in invalid state',
  '10027': "This clientId doesn't exist",
  '10028': 'Non-existent client for the given wallet',
  '10029': 'Non-existent client for the given document',
  '10030': 'sci must be between 8 and 35 characters',
  '10031': 'The payinId does not exist',
  '11000': 'Unable to read user informations. Database error.',
  '11001': 'Unable to create user. Database Error.',
  '11002': 'Unable to update the user. User does not exist',
  '11003': 'Unable to update the user. Database error.',
  '11004': 'Title is required',
  '11005': "Civility value must be either 'M' or 'MME' or 'MLLE'",
  '11006': 'Firstname is required.',
  '11007': 'The lastname is required.',
  '11008': 'The email is required.',
  '11009': 'The email is not valid.',
  '11010': 'The address is required ',
  '11011': 'The post code is required.',
  '11012': 'The city is required.',
  '11013': 'The country is required. Format ISO 3166-1 alpha-2',
  '11014': 'Impossible to cancel, the user does not exist',
  '11015': 'Impossible to cancel, the origin not specified or incorrect',
  '11016': 'Impossible to cancel user, he is already in canceled status',
  '11017': 'Impossible to cancel user. Wrong userId',
  '11018': 'Impossible to cancel user. Database error',
  '11019': 'The email is already used',
  '11020': 'Impossible to create user. The parent type must be provided',
  '11021': 'Impossible to create user. Value available for Parent Type : ',
  '11022': 'Impossible to create user. Parent User does not exist',
  '11023': 'Impossible to update the kyc review. User does not exist.',
  '11025': 'legalRegistrationNumber is required.',
  '11026': 'legalRegistrationDate is required (format : YYYY-MM-DD',
  '11027': 'legalName is required.',
  '11028': 'legalForm is required.',
  '11029': 'legalShareCapital is required',
  '11030': 'phone is required.',
  '11032': 'legalNumberOfEmployeeRange is required.',
  '11033': 'legalNetIncomeRange is required.',
  '11034': 'legalAnnualTurnOver is required.',
  '11035': 'placeOfBirth is required.',
  '11036': 'birthCountry is required. Format ISO 3166-1 alpha-2',
  '11037': 'nationality is required.',
  '11038': 'userTypeId is required.',
  '11039': 'birthday is required (format : YYYY-MM-DD',
  '11040': 'userIdKyc is required.',
  '11041': 'user does not exist.',
  '11042': 'kycReview is required.',
  '11043': 'kycReview value is incorrect.',
  '11044': 'kycReviewComment is required.',
  '11045': 'Internal error during controlKyc.',
  '11046': 'userIdKyc is required.',
  '11047': 'user does not exist.',
  '11048': 'kycLevel is required.',
  '11049': 'kycLevel value is incorrect.',
  '11050': 'Internal error during updateKycLevel.',
  '11051':
    'nationalityOther is not in the right format. Format ISO 3166-1 alpha-2',
  '11052': 'legalRegistrationDate is not in the right format (YYYY-MM-DD',
  '11054': 'legalNumberOfEmployeeRange is not correct. Values authorized : ',
  '11055': 'legalNetIncomeRange is not correct. Values authorized : ',
  '11056': 'legalAnnualTurnOver is not correct. Values authorized : ',
  '11057': 'title is not correct. Values authorized ',
  '11058': 'birthday is not in the right format. Format YYYY-MM-DD',
  '11059': 'birthCountry is not in the right format. Format ISO 3166-1 alpha-2',
  '11060': 'nationality is not in the right format. Format ISO 3166-1 alpha-2',
  '11062': 'Impossible to update the kyc review. KYC entity is not created',
  '11063':
    'Impossible to update the kyc review. Phone number can not contains a space character. It can begin by the ',
  '11064': 'Impossible to Freeze Assets. userIdFreezed is mandatory.',
  '11065': 'Impossible to Freeze Assets. userIdFreezed does not exist.',
  '11066': 'Impossible to Freeze Assets. isFrozen is mandatory.',
  '11067': "User's assets already freezed",
  '11068': "User's assets already not freezed",
  '11069': 'Impossible to Freeze Assets. comment is mandatory.',
  '11070': 'Impossible to Freeze Assets. Internal Error.',
  '11072': 'occupation is required.',
  '11073': 'incomeRange is required.',
  '11074': 'userId is missing.',
  '11075': 'userId does not exist for the client.',
  '11076': 'sci is empty',
  '11077': 'sci must be between 17 and 35 characters',
  '11078': 'The language provided does not comply with ISO 639 (alpha-2 norm.',
  '11080':
    "The field 'personalAssets' must have a value compliant with the documentation",
  '11082':
    "The field 'taxResidence' has a value that does not comply with provided enum values",
  '11083':
    "The field 'taxResidence' has a value that does not comply with provided enum values",
  '11084':
    "The field 'taxResidence' is not set to France, In this case you must provide the Fiscal Identification Number into the taxNumber parameter",
  //   '11084':
  //     "The field 'taxResidence' is provided in request and is not set to France, In this case you must provide the Fiscal Identification Number into the taxNumber parameter",
  '11085':
    "The field 'personalAssets' is provided but the value does not comply with provided enum values",
  '11086': 'User is not found in the database',
  '11093': 'User is not found in the database',
  '11094': 'User is not found in the database',
  '11087': 'The format must follow the norm : ISO-3166-1 alpha-2',
  '11088': "User's kyc_level status is refused",
  '11089': "user can't block wallets linked to",
  '11090': 'user have not wallets linked to',
  // '11089': "User's address1 has a line breaker",
  // '11090': "User's address2 has a line breaker",
  '11091': 'No rows was updated',
  '11095': 'employeeType is missing',
  '11096': 'employeeType is invalid',
  '11097': 'controllingPersonType is invalid',
  '11098': 'controllingPersonType is missing',
  '11099': 'specifiedUSPerson is missing',
  '11100': 'specifiedUSPerson is provided but are not between 0 and 1',
  '11101': 'entityType is invalid',
  '12001': 'Impossible to read Wallet',
  '12002': 'Impossible to create Wallet',
  '12010': 'Impossible to update Wallet',
  '12011': 'Impossible to update Wallet, the wallet is already canceled',
  '12018':
    'Impossible to change eventPayinStartDate when a gift is already offered',
  '13001': 'Impossible to create Bank Account userId required.',
  '13002':
    'Impossible to create Bank Account, the IBAN is already used by another bank account',
  '13003': 'Impossible to create Bank Account, IBAN is not valid',
  '13004': 'Impossible to create Bank Account, BIC is not valid',
  '13005': 'Impossible to save the Bank Account in the database',
  '13006':
    'Impossible to cancel Bank Account. This Bank Account is already in a status CANCELED.',
  '13007':
    'Impossible to cancel Bank Account. This Bank Account does not exist.',
  '13008':
    'Impossible to cancel Bank Account. It is currently used for a payout.',
  '13009': 'Impossible to update database for this bankaccountId',
  '13010': 'Impossible to read Bankaccount',
  '13011': 'Impossible to update Bankaccount. This Bankaccount does not exist.',
  '13013': 'Impossible to update database for this bankaccountId',
  '13014': 'bankaccountTransactionType is missing',
  '13015': 'bankaccountBIC is missing',
  '13016': 'Impossible to create Bank Account, IBAN required',
  '12020': 'Impossible to Cancel Wallet. The wallet does not exist',
  '12021': 'Impossible to create Wallet image repository',
  '12022': 'Impossible to create Wallet image file',
  '12023': 'Impossible to create Wallet, Tarif is not comform',
  '12024': 'Impossible to create Wallet, Tarif does not exist',
  '12025': 'Impossible to create Wallet, Alias is not unique',
  '12026':
    "Unable to create Wallet. The unicity of the alias can't be checked.",
  '12027':
    "Impossible to update Wallet, eventPayinEndDate can't be lower than today",
  '12028': 'Impossible to update Wallet, WalletId is not valid',
  '12029': 'Impossible to Cancel Wallet, WalletId is not valid',
  '12030':
    'Impossible to Cancel Wallet, The wallet is already in status CANCELLED',
  '12031': 'Impossible to create Wallet, User Id required',
  '12032': 'Impossible to create Wallet, User Id not permitted',
  '12033': 'Impossible to create Wallet, Joint User Id not valid',
  '12034': 'Impossible ot create Wallet, Currency must be EUR',
  '12035':
    'Unable to create the wallet. eventPayinEndDate cannot be earlier than eventPayinStartDate',
  '12036': 'Impossible to create Wallet, Name is required',
  '12037': 'Unable to create wallet. The event Payin Start Date is mandatory.',
  '12038': 'Unable to create wallet. The event Payin End Date is mandatory.',
  '12039': 'Unable to create wallet. The origin is not correct',
  '12040': 'Unable to Cancel wallet. The origin is not correct',
  '12041': 'Unable to Cancel wallet. Balance positive.',
  '12042': 'Unable to retrieve a wallet in the Database',
  '12043': 'No status retrieved for wallet in Database',
  '12044': 'The status linked to the wallet has no name',
  '12045': 'The wallet has no user linked impossible to continue',
  '12046': 'The wallet has no Tariff',
  '12047': 'The wallet has no Tariff specified for an incoming SCTR',
  '12048': 'The wallet has no Tariff specified for an incoming SDDR',
  '12049': 'The walletTypeId is missing',
  '12050':
    'The walletTypeId must be 9 (Electronic Money Wallet, or 10 (Payment Account Wallet, or 13 (Mirror Account',
  '12051':
    'Impossible to change the type of the wallet due to presence of transactions for this wallet',
  '12052': 'The walletId is missing',
  '12053': 'The beneficiaryName is missing',
  '12054': 'The beneficiaryAddress is missing',
  '12056': 'The beneficiaryBic is missing',
  '12057': 'No status retrieved for wallet in Database',
  '12058': 'The beneficiaryName is not valid',
  '12059': 'The beneficiaryAddress is not valid',
  '12060': 'The beneficiaryIban is not valid',
  '12061': 'The wallet cannot be closed yet',
  '12062': 'The wallet is already closed',
  '12063': 'The wallet have not cards which linked to',
  '12064': 'Error during block card linked to the wallet',
  '12065':
    'The request must contains at least one of those inputs : walletId, eventAlias, userId, walletTypeId, walletTag',

  '14001': 'paymentMethodId does not exist',
  '14002': 'cartId does not exist',
  '14003': 'Impossible to create Payin',
  '14004': 'Impossible to create the transaction',
  '14005': 'This payin does not exist',
  '14006': 'Impossible to update the payin',
  '14007': 'Impossible to cancel the payin',
  '14008': 'The userId does not exist or is not in a status VALIDATED.',
  '14009':
    'Unable to create payin with the provided walletId. It does not exist.',
  '14010':
    "Action Denied, User doen't exist or User is CANCELED OR PENDING (waiting for KYC validation",
  '14011': 'Unable to create payin. The wallet is not in a status VALIDATED',
  '14012':
    'The specified currency for payin must be the same as the currency of the wallet',
  '14013': 'The cart specified must be in a status PENDING.',
  '14014': 'Distributor Fees must be greater than 0.',
  '14015': 'The provided amount must be greater than 1.',
  '14016': 'The sum of subTotals must be equal to the provided amount.',
  '14017': 'Currency does not exist',
  '14018':
    'Unable to cancel the payin. The payin is already in a status CANCELED',
  '14019': 'Unable to cancel the payin. The payin is in a status VALIDATED',
  '14020': 'Unable to cancel the payin. The payin is in a status PENDING',
  '14021': 'Impossible to read Payin',
  '14022':
    'Unable to create payin. The payin period has not started or has ended',
  '14023': "The given userId doesn't match with the oneclickcardId",
  '14024': 'Unable to get oneclickcard datas',
  '14025': 'Error during the oneclickcard payment',
  '14026':
    'Your client account has no company name set. Please contact your account manager !',
  '14027': 'No client set for this userId.',
  '14028': 'No currency set or currency invalid for this Wallet',
  '14029': 'The recipient data of this Payin were not found',
  '14030': 'No userTag set for this user',
  '14031': "The Payin or it's user was not found on the Database.",
  '14032':
    'No css found for this client. Please add one. Contact your account manager',
  '14033':
    'The amount provided is not in a good format. Must be a float and greater than 0',
  '14034': 'All the urls provided must have an HTTPS Scheme',
  '14035':
    'The user should have a firstname and a lastname set to make a Payin using Hipay',
  '14036': 'The currency of the wallet is not supported by the Hipay API',
  '14037': 'The user data were not found',
  '14038': 'The URL provided for your CSS Stylesheet must be a valid HTTPS URL',
  '14039':
    'The country provided for the donor user does not comply with ISO 3166-1 (alpha 2 norm. We cannot pass it to Hipay Service',
  '14040':
    'The country provided for the recipient user does not comply with ISO 3166-1 (alpha 2 norm. We cannot pass it to Hipay Service',
  '14041': 'The Payin Request Made to Hipay has occured an error.',
  '14042':
    "The 'paymentProduct' field must be set in your payinCreate call and must be part of the list.",
  '14043':
    "The 'oneclickcardId' field must be set in your payinCreate call in order for us to transmit it to Hipay (cardtoken",
  '14044': 'No Payin found in database, impossible to get the payin status id',
  '14045': 'The order was not found or does not have a status',
  '14046':
    'You must provide HTTPS return urls in your call (paymentAcceptedUrl, paymentRefusedUrl, paymentWaitingUrl, paymentExceptionUrl, paymentCanceledUrl.  ',
  '14047':
    'The amount provided must be a float (decimal separator must be a point',
  '14048': "The country of the user doesn't comply with  ISO 3166-1 alpha 2",
};
