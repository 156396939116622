import { PaletteType } from '@material-ui/core';
import { VIEW } from '../utils/constant';

export const THEME_TYPE = 'theme';
export const setThemeTypeStorage = (type: PaletteType) => {
  localStorage.setItem(THEME_TYPE, type);
};
export const getThemeTypeStorage = (): PaletteType => {
  const result = localStorage.getItem(THEME_TYPE);
  if (result) {
    return result === VIEW.LIGHT ? VIEW.LIGHT : VIEW.DARK;
  }
  return VIEW.LIGHT;
};
export const ACCESS_TOKEN_KEY = btoa('access_token_bo');
export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const isAuthenticated = (): boolean =>
  !!localStorage.getItem(ACCESS_TOKEN_KEY);
export const clearLocalStorage = () => {
  const theme = getThemeTypeStorage();
  localStorage.clear();
  setThemeTypeStorage(theme);
};
export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export enum FILTER_STORAGE {
  COMPANY = 'cp_filter',
  CARD = 'ca_filter',
  TRANSACTION = 'tx_filter',
  SERVICE = 'se_filter',
}

export enum PAGE_STORAGE {
  COMPANY = 'cp_page',
  CARD = 'ca_page',
  TRANSACTION = 'tx_page',
}

export const getFilterStorage = (key: string) => {
  return localStorage.getItem(key);
};
export const setFilterStorage = (key: string, value: string) =>
  localStorage.setItem(key, value);
