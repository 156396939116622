export default {
  'sapheer.subscription.checkBox': 'Abonnement',
  'sapheer.subscriptionNotOk.checkBox': 'Rattrapage',
  'sapheer.frais.checkBox': 'Retrait',
  'beneficiary.creditorName': 'Créditeur',
  'transaction.grid.noData': 'Pas de transaction à afficher',
  'transaction.title': 'Titre',
  'transaction.dateTransaction': "Date d'exécution",
  'transaction.amount': 'Montant',
  'transaction.currency': 'Monnaie',
  'transaction.modalityType': 'ENTRANT/SORTANT',
  'transaction.transactionState': 'Etat',
  'transaction.companyName': 'Entreprise',
  'transaction.treezorTransactionId': 'ID dans Treezor',
  'transaction.walletbic': 'BIC',
  'transaction.walletIban': 'IBAN',
  'transaction.search.noResult': 'Pas de transaction trouvée',
  'title.transaction.details': 'Détails de la transaction',
  'related.transaction.account': 'Expéditeur',
  'related.transaction.wallet': 'Wallet Associé',
  'transaction.document.error':
    'Erreur lors de la réception du document , vérifiez votre connexion internet',
  'transaction.dateCreateTransaction': 'Date de Création',
  'dialog.create.prelevement.emitter': 'Emetteur',
  'related.transaction.beneficiary': 'Bénéficiaire',
  'beneficiary.name': 'Nom du Bénéficiaire',
  'beneficiary.treezorUserId': 'Id dans Treezor',
  'beneficiary.iban': 'IBAN',
  'beneficiary.bic': 'BIC',
  'beneficiary.walletId': 'Id du Wallet',
  'beneficiary.companyName': 'Entreprise',
  'label.transactionState': 'Etat',
  'dialog.create.virement.iban': 'IBAN',
  'dialog.create.virement.details': 'Intitulé',
  'dialog.create.virement.title': 'Intitulé',
  'dialog.create.virement.title.SMART_OFFER': 'Forfait Smart',
  'dialog.create.virement.title.PREMIUM_OFFER': 'Forfait Premium',
  'dialog.create.virement.title.CORPORATE_OFFER': 'Forfait Corporate',
  'dialog.create.virement.title.WITHDRAWAL_FEE': 'Frais de retrait',
  'dialog.create.virement.title.PAYIN_FEE': 'Frais de virement',
  'dialog.create.virement.title.PAYOUT_FEE': 'Frais de prélèvement',
  'dialog.create.virement.title.SERVICE_PLUS': 'Frais Service plus',
  'dialog.create.virement.title.CLOSE': 'Frais de clôture',
  'dialog.create.virement.title.JUDICAL_REQUISITION':
    'Frais de réquisition judiciaire',
  'dialog.create.virement.title.NOTICE_HOLDER':
    'Frais d’avis à tiers détenteur',
  'dialog.create.virement.title.OPPOSITION_HOLDER':
    'Frais d’opposition à tiers détenteur',
  'dialog.create.virement.title.SEIZYRE_BY_BAILIFF':
    'Frais de saisie par huissier',
  'dialog.create.virement.title.INTERVENTION_COMMISSION':
    'Frais de commission d’intervention',
  'dialog.create.virement.title.OTHER': 'Autres',
  'dialog.create.virement.title.subscription': 'Forfait',
  'dialog.create.virement.title.CARD_LOSS': 'Frais de carte - Perte / Vol',
  'dialog.create.virement.title.CREATE_CARD': 'Frais de carte - Création',
  'dialog.create.virement.details.amount': 'Montant',
  'dialog.create.virement.beneficiary': 'Bénéficiaire',
  'dialog.create.transaction.type': 'Type',
  'dialog.button.cancel': 'Annuler',
  'dialog.button.create': 'Créer',
  'toolbar.button.addTransaction': 'Transaction',
  'dialog.createTransaction': 'Création de transaction',
  'transaction.iban.notValid': "L'IBAN saisi est incorrect",
  'transaction.title.empty': "L'intitulé du virement est requis",
  'transaction.amount.empty': 'Montant invalide',
  'transaction.amount.nan': 'Le montant doit être un chiffre',
  'transaction.amount.min':
    'Le montant de la transaction doit être supérieur à 1 Euro',
  'transaction.virement.create.error':
    'Erreur lors de la création du virement.',
  'transaction.virement.create.success':
    'Nouvelle transaction ajoutée avec succès.',
  'transaction.virement.confirm':
    'Souhaitez-vous réellement créer cette transaction ?',
  'transaction.virement.create.error.spark_beneficiary_not_configured':
    'Veuillez configurer votre bénéficiaire',
  'dialog.create.virement.txtype.ACCOUNT_OFFER': 'Offre',
  'dialog.create.virement.txtype.STANDARD': 'Standard',
  'dialog.create.virement.txtype.PAYIN_FEE': 'Sapheer - Frais',
  'dialog.create.virement.txtype.SAPHEER_ABONNEMENT': 'Sapheer - Forfait',
  'dialog.create.virement.txtype.ATM_FEE': 'Frais retrait',
  'dialog.create.virement.txtype.CARD_FEE': 'Frais carte',
  'dialog.create.virement.txtype.CARD_EXCESS_FEE': 'Frais excès carte',
  'dialog.create.virement.txtype.DEPOSIT_CAPITAL': 'Dépôt capital',
  'dialog.create.virement.txtype.SAPHEER_OTHER': 'Sapheer - Autres',
  'dialog.create.virement.txtype.empty':
    'Veuillez choisir le type de la transaction',

  'transaction.payout.type': 'Frais',
  'transaction.payout.abonnement': 'Abonnement',
  'transaction.modality.cheque': 'Chèque',
  'transaction.label.card.in': 'Topup',
  'transaction.payout.refund': 'Remboursement',
  'transaction.label.card.out': 'Retrait',
  'transaction.label.card.payment': 'Paiement',
  'transaction.label.facture': 'Frais',
  'transaction.label.modality.payin': 'Wallet to Wallet',

  'toolbar.button.export': 'Exporter',

  'dialog.exportTransaction': 'Exporter les transactions',

  'export.startDate.empty': 'Date début vide',
  'export.endDate.empty': 'Date fin vide',
  'toolbar.button.filter': 'Filtre',
  'dialog.filterTransaction': 'Filtrer les transactions',

  'tx.filter.card.label': 'Carte(Retrait - Topup - Paiement)',
  'tx.filter.virement.label': 'Virement',
  'transaction.sepa.inst': 'Virement Inst',
  'tx.filter.transfert.label': 'Wallet to wallet',
  'tx.filter.topup.label': 'Topup',
  'tx.filter.card.payment.label': 'Paiement',
  'tx.filter.card.withdrawal.label': 'Retrait',

  'sapheer.beneficiary.checkBox': `Facturation`,
  'company.transaction.state': `Facturation`,
  'transaction.same.iban': 'Veuillez vérifier les deux iban saisies.',
  'transaction.offer.error': 'Veuillez ajouter une offre',
  'input.walletId': 'Wallet ID',

  'transaction.payout.out': 'Virement',
  'transaction.card.command': 'Commande carte',
  'transaction.card.deposit': 'Dépôt de capital',

  'transaction.payout.delete': 'Supprimer',
  'transaction.payout.delete.confirm': `Voulez-vous vraiment supprimer cette transaction ?`,
  'btn.beneficiary.cancelled': 'Annuler beneficiaire',
  'beneficiary.cancelled': 'Beneficiaire annulé',
  'attachment.deleted': 'Pièce jointe supprimée',
  'phone.updated': 'Numéro de téléphone modifié avec succès',
  'dialog.create.virement.bic': 'BIC',
  'transaction.bic.error': 'Le BIC saisi est invalide',
  'dialog.create.virement.name': 'Nom',
  'transaction.name.error': 'Le nom saisi est invalide',
  'synchronize.success': 'Synchronisation effectuée avec succès',
  'transaction.codeMorInfo': `Plus d'informations`,
  'transaction.label.transactionProof': 'Preuve virement',

  'transaction.state.termined': 'Validé',
  'transaction.state.scheduled': 'Future',
  'transaction.state.canceled': 'Annulé',
  'transaction.state.pending': 'En attente',
  'transaction.state.rejected': 'Rejeté',
  'transaction.state.revered': 'Libéré',
  'transaction.state.declined': 'Refusé',
  'transaction.state.unvalitaded': 'À valider',
  'transaction.state.settled': 'Payé',
  'transaction.state.accepted': 'Accepté',
  'transaction.state.refund': 'Remboursé',
  'transaction.state.cleared': 'Supprimé',
  'transaction.state.blocked.by.treezor': 'Analyse',
  'transaction.state.is.fraud': 'Risque',

  VIREMENT_INST: 'Virement Inst',
};
