export default {
  'input.iban': 'Iban',
  'kyc.usSpecifiedPerson': `I certify that I am not a US Person`,
  'kyc.occupation': 'Occupation',
  'kyc.incomeRange': 'Income range (K€)',
  'kyc.personalAssets': 'Personal assets (K€)',
  'label.singupProgression': `Sign up`,
  'company.grid.noData': 'No account to display',
  'account.search.noResult': 'No account found',
  'kyc.type': 'Type',
  'kyc.employee.type': `Employee type`,
  'email.error.data': 'The email field is required',
  'address.error.data': 'The address field is required',
  'city.error.data': 'The city field is required',
  'name.error.data': 'The name field is required',
  'firstName.error.data': `The firstname field is required`,
  'type.error.data': `The type field is required`,
  'percent.error.data': `The percent field is required`,
  'employeeType.error.data': `The employee type field is required`,
  'postCode.error.data': `The post code field is required`,
  'birthday.error.data': `The birthday field is required`,
  'placeOfBirth.error.data': `The place of birth field is required`,
  'birthCountry.error.data': `The birth country field is required`,
  'birthPostCode.error.data': `The birth post code field is required`,
  'nationality.error.data': `Nationality field is required`,
  'specifiedUSPerson.error.data': `The checkbox is required`,
  'percent.not.valid': `Le percent not valid (Total Percent < 100%)`,
  'title.member': 'Member',
  'company.leader': 'Leader',
  'company.mandatory': 'Representative',
  'company.actionnaire': 'Shareholder',
  'not.achieved': 'None',
  'kyc.member': 'KYC ',
  'level.none': 'None',
  'level.pending': 'Pending',
  'level.regular': 'Regular',
  'level.strong': 'Strong',
  'level.refused': 'Refused',
  'level.investigating': 'Investigating',
  'review.none': 'None',
  'review.pending': 'Pending',
  'review.validated': 'Validated',
  'review.refused': 'Refused',
  'kyc.level': 'Kyc Level',
  'kyc.review': 'Kyc Review',
  'kyc.review.comment': 'Kyc review comment',
  'kyc.request': 'Kyc requested/not requested',
  'kyc.requested': 'Document send',
  'kyc.notRequested': 'Document not send',
  'kyc.treezorUserState': 'Treezor user state',
  'kyc.offer': 'Offer',
  'account.report.success': 'Document report sent',
  'account.report.error': 'Error while sending Document report',
  'kyc.not.specified': 'Not Specified',
  'kyc.treezorId': 'Treezor ID',

  'document.update.success': 'Document updated with success',
  'company.add.doc.empty': `Please insert two document`,
  'company.doc.edit.empty': 'Please insert a document',
  'company.update.doc.success': 'Company document updated with success',
  'company.add.doc.success': 'Company document add with success',

  'legalRegistrationNumber.empty': 'Please provide the SIRET of the company',
  'legalRegistrationDate.empty': `Please provide company registration date`,
  'country.empty': 'Please provide the country of the company',
  'legalSector.empty': `Please provide the Company's Business Sector`,
  'legalNumberOfEmployeeRange.empty': `Please provide the number of employees of the company`,
  'legalNetIncomeRange.empty':
    'Please provide a range of net income for the company',
  'legalAnnualTurnOver.empty': `Please provide the Annual Turnover of the company`,

  'personLists.quit.title': 'Quit without saving',
  'personLists.quit.question': 'Unsaved changes will be lost',
  'personLists.save': 'Save',
  'personLists.close': 'Close',
  'personLists.firstID': 'First ID',
  'personLists.proofOfAddress': 'Proof of address',
  'personLists.secondID': 'Second ID',
  'personLists.hostID': 'Identity document of the host',
  'personLists.hostProofOfAddress': 'Proof of address of the host',
  'personLists.hostProofOfAttestation': 'Hosting certificate',
  'personLists.documentReport': 'Are you sure to postpone this document?',
  'personLists.report': 'Report',

  'cin.recto': `National ID card`,
  'cin.verso': `National Identity Card Back (Optional)`,

  'shareholder.type.none': 'Not an employee',
  'shareholder.type.leader': 'Legal Representative',
  'shareholder.type.employee': 'Beneficial Owner is an employee',

  'dialog.exportCompany': 'Export companies',
  solde: 'Authorized balance ',

  'identity.file.recto': `Identity document`,
  'identity.file.verso': `Identity document Verso (Optional)`,
  'identity2.file.recto': `Additional Vigilance`,
  'identity2.file.verso': `Additional Vigilance Verso (Optional)`,
  'identity3.file.recto': `Host identity document`,
  'identity3.file.verso': `Host identity document Verso (Optional)`,
  'company.disable.message': `Are you sure to delete this company ?`,
  'company.disable.account.message': `Are you sure to delete this account ?`,
  'company.disable.account.title': `Delete`,
  'company.disable.title': `Delete this company`,
  'company.disable.account.success': ` Account successfully deleted `,
  'company.disable.success': ` Company successfully deleted `,
  'company.disable.account.error': ` Error occur when deleting this account`,
  'company.disable.error': ` Error occur when deleting this company`,

  'kyc.doc.delete.message': 'Are you sure to delete this document ?',

  'stepFour.file.urssaf': `URSSAF certificate`,
  'stepFour.file.virement': `Incoming transfer of €20 from an account in the same name`,
  'stepFour.file.orderProfessional': `Document justifying the status of a Professional Order`,
  'stepFour.file.insee': 'INSEE opinion',
  'stepFour.file.sirene': `Situation report SIRENE`,
  'stepFour.create.register': 'K-Bis less than 3 months old',
  'stepFour.docProof': `Certified articles of association`,
  'stepFour.exist.docProof': `Certified articles of association < 1 year`,

  'company.card.create': 'Cards',
  'company.archive': 'Inactive',

  'signup.company.information': `Company`,
  'signup.company.leader': `Leader`,
  'signup.company.actionary': `Shareholder`,
  'signup.company.contract': `Contract`,
  'signup.company.choice': `Offer`,
  'signup.company.finish': 'Finish',
  'input.add': 'Add',
  'input.balance': 'Balance',
  'kyc.percent': 'Percent',

  'account.close.title': 'Close',
  'account.close.message': 'Are you sure to close this account',
  'account.close.success': `Account close with success`,
  'account.close.error': 'Close account error',
  'company.readOnly': 'Limited',
  'company.freezed': 'Freezed',
  'input.country': 'Country',

  'existing.company.creation': 'Creation',
  'existing.company.registered': 'Registered',
  'existing.company.deleted': 'Close',

  'user.freezed.value': 'yes',
  'user.notfrezed;value': 'no',

  'user.freezed': 'Freezed',

  'company.count.transaction.payin': 'PayIn',
  'company.count.transaction.payout': 'PayOut',
  'company.count.transaction.paiements': 'Paiements',
  'company.count.transaction.withdrawal': 'Withdrawal',
  'company.count.transaction.topup': 'TopUp',

  'label.dateValidation': 'Validation',
};
