import { API_URL } from './config';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import resolvers from './apollo/resolvers';
import typeDefs from './apollo/typeDefs';
import { persistCache } from 'apollo-cache-persist';

const createClient = (token: string | null) => {
  const httpLink = createHttpLink({
    uri: `${API_URL}`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  // Override cache to avoid error when reading an undefined cache
  const cache: InMemoryCache & { readQueryClone?: any } = new InMemoryCache();

  // persistCache({
  //   cache,
  //   storage: window.localStorage as any,
  // });

  cache.readQueryClone = cache.readQuery;
  cache.readQuery = (...arg) => {
    try {
      return cache.readQueryClone(...arg);
    } catch (error) {
      return undefined;
    }
  };

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    typeDefs,
    resolvers,
  });
};

export default createClient;
