import React, {
  ComponentClass,
  ComponentPropsWithRef,
  ComponentType,
  ReactElement,
} from 'react';
import Loadable, {
  LoadableComponent,
  LoadingComponentProps,
} from 'react-loadable';
import CustomProgress from '../../Common/CustomProgress/CustomProgress';

type ExoticComponent<P = {}> = (props: P) => ReactElement<any> | null;

function Loadman<T extends ComponentType<any>>(
  loader: Promise<{ default: T }>,
  loading?: ComponentClass<LoadingComponentProps, any>,
): ExoticComponent<ComponentPropsWithRef<T>> & LoadableComponent {
  const progress = () => <CustomProgress />;
  return Loadable({
    loader: () => loader,
    loading: loading || progress,
  }) as any;
}
export default Loadman;
