export default {
  'card.foreign': 'Foreigner',
  'card.online': 'Online',
  'card.nfc': 'No contact',
  'card.active': 'Activate',
  'card.withContract': '',
  'card.cardPin.title': 'Changer code PIN',
  'card.cardDialogue.physicalTitle': 'Carte physique',
  'card.cardDialogue.virtualTitle': 'Carte virtuelle',
  'card.cardDialogue.type': 'Types de carte',
  'card.cardDialogue.ownerTitle': 'Propriétaire',
  'card.cardDialogue.codeTitle': 'Choisir un code à 4 chiffres',
  'card.cardDialogue.priceTitle': 'Limites',

  'card.paiement': 'Paiement :',
  'card.retrait': 'Cash withdrawal :',
  'card.retrait.pile': 'Cash withdrawal',
  'card.modal.paiement': 'Payment',
  'card.modal.retrait': 'Retrait',
  'card.modal.address': 'Addresse',
  'card.pin.notMatch': 'The pin code not same',
  'card.add.pin.easy': 'The code pin is easy',
  'card.add.pin.empty': 'The pin code is empty',
  'card.new': 'New card',
  'addCard.success': 'Card added with success',
  'transaction.merchantAddress': 'Address',
  'transaction.authorizationResponseCode': 'Response code',
  'transaction.authorizationNote': 'Note',
  'error.pinNotComplex':
    'Please enter a PIN code with 4 non-consecutive and non-repetitive digits',
  'card.type.virtuel': 'Virtual',
  'card.type.physical': 'physical',
  'dialog.exportCard': 'Export cards',
  'setting.download.export.cards.error':
    'The problem occurred while exporting cards.',
};
