export default {
  'card.foreign': 'Etranger',
  'card.online': 'En ligne',
  'card.nfc': 'Sans Contact',
  'card.active': 'Activer',

  'card.paiement': 'Paiement :',
  'card.retrait': 'Retrait :',
  'card.retrait.pile': 'Retrait',
  'card.modal.paiement': 'Paiement',
  'card.modal.retrait': 'Retrait',

  'card.cardPin.title': 'Changer code PIN',
  'card.cardDialogue.physicalTitle': 'Carte physique',
  'card.cardDialogue.virtualTitle': 'Carte virtuelle',
  'card.cardDialogue.type': 'Types de carte',
  'card.cardDialogue.ownerTitle': 'Propriétaire',
  'card.cardDialogue.codeTitle': 'Choisir un code à 4 chiffres',
  'card.cardDialogue.priceTitle': 'Limites',
  'card.modal.address': 'Adresse',
  'card.add.category': 'Veuillez choisir une catégorie',
  'card.add.noMember': 'Veuillez choisir un membre',
  'card.add.noDeliveryAddress': 'Veuillez choisir une adresse',
  'card.pin.notMatch': 'Les codes pin insérées sont différents',
  'card.add.pin.easy': 'Le Code pin est trop facile',
  'card.add.pin.empty': 'Le Code pin courant est vide',
  'card.new': 'Nouvelle carte',
  'addCard.success': 'Carte ajoutée avec succès',
  'transaction.merchantAddress': 'Adresse',
  'transaction.authorizationResponseCode': 'Code réponse',
  'transaction.authorizationNote': 'Note',
  'error.pinNotComplex':
    'Veuillez saisir un code PIN comportant 4 chiffres non consécutifs et non répétitifs.',
  'card.type.virtuel': 'Virtuelle',
  'card.type.physical': 'Physique',
  'dialog.exportCard': 'Exporter les cartes',
  'setting.download.export.cards.error': `Problème est survenu lors de l'exportation des cartes.`,
};
