import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import createClient from './apolloClient';
import App from './App';
import './index.css';
import messages from './locales/messages';
import { getLocale } from './services/Locale';
import { ACCESS_TOKEN_KEY } from './services/LocalStorage';
import * as serviceWorker from './serviceWorker';

const locale = getLocale();

addLocaleData([...en, ...fr]);
const token = localStorage.getItem(ACCESS_TOKEN_KEY);
const client = createClient(token);

ReactDOM.render(
  <ApolloProvider client={client}>
    <IntlProvider locale={locale} messages={(messages as any)[locale]}>
      <App />
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
