export const API_URL = process.env.REACT_APP_API_URL;
export const APP_WS_URL = process.env.REACT_APP_WS_URL;

export const CLOUDINARY_NAME = process.env.REACT_APP_CLOUDINARY_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

export const GOOGLE_DRIVE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;

export const GOOGLE_DRIVE_CLIENT_SECRET =
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_SECRET;

export const GOOGLE_DRIVE_CLIENT_REDIRECT_URL =
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_REDIRECT_URL;

export const GOOGLE_DRIVE_REFRESH_TOKEN =
  process.env.REACT_APP_GOOGLE_DRIVE_REFRESH_TOKEN;

export const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;

export const OFFER_START_STRING = 'Start';
