export default {
  'transaction.fraud': 'Risk',
  maintenance: 'Sapheer is in maintenance, come back in a few minutes',
  'maintenance.title': `Maintenance in progress`,
  'maintenance.subtitle1': `We are improving our platform`,
  'maintenance.subtitle2': `Come back in a few minutes`,
  'error.form.empty': 'Please fill the form up',
  'date.invalid': `Invalid date`,
  'error.max.date': 'Please select a previous date',
  'error.min.date': 'Please select a next date',
  'error.uploadFileTooLarge': 'File too large',
  'all.empty': 'Please select at least one input',
  'url.notValid': `Uri not valid`,
  'card.grid.noData': 'No cards',
  confirm: 'Confirm',
  back: 'Back',
  'label.search.criteria': 'Criteria',
  LEGAL_NAME: 'Legal name',
  OFFER_NAME: 'Offer',
  TREEZOR_USER_PHYSIC_ID: 'Physical User ID',
  TREEZOR_USER_MORALE_ID: 'Business User ID',
  TREEZOR_WALLET_ID: 'Wallet ID',
  TREEZOR_CARD_ID: 'Card ID',
  TITLE: 'Title',
  MONTANTS: 'Amount',
  BENEFICIARY_NAME: 'Beneficiary name',
  CARD_PERSON_NAME: 'Person card',
  TREEZOR_TRANSACTION_ID: 'Transaction ID',
  OTHER: 'Other',
  'btn.validate': 'Validate',
  'btn.reset': 'Reset',
  'file.empty': 'Select a file',
  'btn.file': 'Select File',
  'btn.upload': 'Upload',
  'btn.cancel': 'Cancel',
  'btn.delete': 'Delete',
  'btn.add': 'Add',
  'btn.download': 'Download',
  'data.not.found': `Data not found, please check the information`,

  'statement.info.empty': 'Please add start and end date',
  'synchronize.error': 'Synchronize error',

  'not.executed': `Error when executing webhook`,
  'not.deleted': `Error when deleting webhook`,
  'no.hooks': `Error when getting webhooks`,
};
