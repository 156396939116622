export default {
  'signin.title': 'Connexion',
  signin: 'Se connecter',
  signup: "S'inscrire",
  'info.signup':
    "Si vous n'avez pas encore de compte. Vous pouvez vous inscrire en cliquant sur le bouton ci-dessous.",
  password_reset: 'Mot de passe oublié? ',
  'login.authentication.failed':
    'Veuillez vérifier si votre email et votre mot de passe sont corrects',
  'user.email': 'Email',
  'user.password': 'Mot de passe',
  'user.confirmPassword': 'Confirmer le mot de passe',
  'button.login': "S'authentifié",
  'pwd.reset': 'Changer mot de passe',
  'error.invalidPassword': 'Mot de passe invalide',
  'error.invalidPin': 'Code Pin invalide',
  click: 'cliquez ici',
};
