export default {
  'offer.grid.noData': "Pas d' offre à afficher ",
  'offer.name': "Nom de l'offre",
  'offer.price': 'Prix',
  'offer.error.data': 'Veuillez remplir le champ prix corrèctement',
  'offer.error.update': "Erreur lors de la mise à jour de l'offre",
  'offer.update.success': 'Offre mis à jour',
  'toolbar.offer.details': "Détails de l'offre",
  'offer.virtualCard': `Carte virtuelle`,
  'offer.physicalCard': `Carte physique`,
  'offer.cardTransactions': `Trx/Carte`,
  'offer.payins': `Virement`,
  'offer.payouts': `Prélèvement`,
  'offer.cheque': `Chèque`,
  'offer.withdrawal': `Retraits`,
  'offer.smartAccess': `Utilisateurs`,
  'offer.servicePlus': `Service Plus`,
  'offer.liveCard': `Live card`,
  'offer.smartDashboard': `Tableau de bord`,
  'offer.categorisationAuto': `Catégorisation automatique`,
  'offer.numeric.error.data': 'Veuillez vérifier et remplir les champs en nombres'
};
